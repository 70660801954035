import {Component, Input, TemplateRef, ViewChild} from '@angular/core';
import {AssetStatus, CaseService, FileUploadComponent, ModalService, MsiModalRef, States, Toast, ToastService} from '@msi/cobalt';
import {IgnoredRow, InvalidRow, RestService} from '../services/rest.service';

type ImportState = {
  errors: Record<string, FileErrors>;
  warnings: Record<string, FileWarnings>;
}

// This type is slightly different from ImportResults in that the invalidRows
// field is a map from sheet name to a list of invalid rows
// as opposed to just one global list
type FileErrors = {
  globalErrors: Array<string>;
  invalidRows: Record<string, Array<InvalidRow>>;
}

type FileWarnings = {
  ignoredRows: Record<string, Array<IgnoredRow>>;
}


@Component({
  selector: 'app-import-export-modal',
  templateUrl: './import-export-modal.component.html',
  providers: [ModalService]
})
export class ImportExportModalComponent {
  @Input() type!: 'type' | 'record';
  @ViewChild(FileUploadComponent) upload: FileUploadComponent;
  @ViewChild(TemplateRef) templateRef: TemplateRef<any>;

  modalRef: MsiModalRef;
  importState: ImportState;
  toasts: Array<Toast> = [];

  constructor(private modalService: ModalService, public rest: RestService, public toastService: ToastService) {
    this.resetImportState();
  }

  resetImportState() {
    this.importState = {errors: {}, warnings: {}};
  }

  clearToasts() {
    for (const toast of this.toasts) {
      this.toastService.hideToast(toast);
    }
  }

  onImport() {
    this.clearToasts();

    this.modalRef = this.modalService.open(this.templateRef, {
      disableClose: true,
      hasBackdrop: true,
    });

    // The FileUploadComponent, and, by extension, this.upload
    // only become available once the modal is shown
    // Unfortunately, I don't think there's another way of guaranteeing that
    // other than setTimeout
    setTimeout(this.initializeUploadManager.bind(this), 100);
  }


  initializeUploadManager() {
    const uploadManager = this.upload.fileUploadManager;
    const caseService: CaseService = uploadManager.caseService;

    caseService.on('callMethod', (method) => {
      if (method !== 'upload') {
        return;
      }
      this.resetImportState();
      this.clearToasts();

      if (uploadManager.assets.length === 0) {
        this.toasts.push(this.toastService.warning('Please add a file before uploading.'));
        return;
      }

      uploadManager.assets.forEach((asset) => {
        asset.status = AssetStatus.VERIFYING;
        asset.progress = 0;

        const file = asset.files[0].file

        const fileExtension = file.name.split('.').pop()
        if (fileExtension !== 'xls' && fileExtension !== 'xlsx') {
          asset.status = AssetStatus.NOT_UPLOADED;
          asset.state = States.ERROR;
          asset.message = 'Invalid file extension. Only Microsoft Excel (.xls/.xlsx) files are supported.';
          return;
        }

        this.rest.importData(this.type, file).subscribe((result) => {
          asset.progress = 100;

          // Group a list of objects by a property
          function groupBy(objs, property) {
            const res = {};
            for (const obj of objs) {
              if (!(obj[property] in res)) {
                res[obj[property]] = [];
              }
              res[obj[property]].push(obj);
            }
            return res;
          }

          if (result.globalErrors.length > 0 || result.invalidRows.length > 0) {
            asset.status = AssetStatus.NOT_UPLOADED;
            asset.state = States.ERROR;

            this.toasts.push(this.toastService.error(`File not imported because of errors. Please correct the errors listed in the "Errors" tab and then try again.`));

            this.importState.errors[file.name] = {
              globalErrors: result.globalErrors,
              invalidRows: groupBy(result.invalidRows, 'sheetName'),
            };
          } else if (result.ignoredRows.length > 0) {
            asset.status = AssetStatus.VERIFIED;
            asset.state = States.DONE;

            this.importState.warnings[file.name] = {
              ignoredRows: groupBy(result.ignoredRows, 'sheetName'),
            }

            this.toasts.push(this.toastService.warning(`Your file has been processed, but some rows have been ignored. Please check the "Ignored Rows" tab for more information.`));
          } else {
            asset.status = AssetStatus.VERIFIED;
            asset.state = States.DONE;

            this.toasts.push(this.toastService.success(`Imported file successfully. Please refresh the page to see the result.`));
          }
        }, (e) => {
          this.toasts.push(this.toastService.error(`An unknown error occurred while importing ${this.type}s. Please try again later.`));

          asset.status = AssetStatus.NOT_UPLOADED;
          asset.state = States.ERROR;
          asset.message = 'Asset not uploaded because of unknown error';
        });
      });
    });
  }

  dismissImport() {
    this.modalRef.close();
    this.clearToasts();
  }

  onExport() {
    this.clearToasts();
    this.rest.exportData(this.type).subscribe(
      () => this.toasts.push(this.toastService.success(`All ${this.type}s successfully exported.`)),
      e => {
        if (e.status === 404) {
          this.toasts.push(this.toastService.warning(`No ${this.type}s found.`));
        } else {
          this.toasts.push(this.toastService.error(`An unknown error occurred while exporting ${this.type}s. Please try again later.`));
        }
      }
    );
  }
}
