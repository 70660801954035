import { Component, OnInit, TemplateRef, ViewChild, Input, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { RestService } from '../services/rest.service';
import { Router } from '@angular/router';
import { ToastService, ContentModalService, MsiModalRef, ModalService } from '@msi/cobalt';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { startWith, map } from 'rxjs/operators';
import { Guid } from 'guid-typescript';
import { firstValueFrom, lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-premise-hazard-record',
  templateUrl: './premise-hazard-record.component.html',
})

// tslint:disable-next-line:component-class-suffix
export class PremiseHazardRecordComponent implements OnInit, OnDestroy {
  @ViewChild('geoverification', { static: true })
  geoverification: TemplateRef<any>;
  @ViewChild('contactdelete', { static: true }) contactdelete: TemplateRef<any>;
  @ViewChild('phzrecorddelete', { static: true }) phzrecorddelete: TemplateRef<any>;
  @ViewChild('attachmentdelete', { static: true }) attachmentdelete: TemplateRef<any>;
  @Input() agencyName: string;

  private PHZRecordDeleteComfirm: MsiModalRef;
  private ContactDeleteComfirm: MsiModalRef;
  private AttachmentDeleteConfirm: MsiModalRef;
  currentAgency;
  currentCustomer;
  ReadyListener;
  CustomerIDListener;
  switchAgencyEventListener;
  DefaultDistanceUnitListener;
  beSwitched = false;
  deletcontactindex; // determind the current selected contact index which should be deleted
  matchLocations: any;
  existingAttachments = [];
  content = document.getElementById('content');
  templateModalData: string;
  templateModalResult: object | null;
  premiseHazardRecordForm: FormGroup;
  rowindex = 0; // determind the current index of records
  Records: any; // saving all records of this user
  HazardTypes: Array<any>; // saving hazardTypes from types-proximities-page
  AttachmentsURI: Array<any>; // saving the uri from CCDrive
  PhoneFormatBoolean = true; // for default phone format (us phone format (XXX)-XXXXXXXXX - extension)
  purgetypes = ['Do not purge', 'Automatically', 'Purge Manually'];
  remainingKey = 0; // if there are more than 30 data for this agency, this variable is larger than 0
  LoadingLock = false; // When we scroll down near the bottom,
  // we should release this as false, once we got more premise hazard data back we should lock this again.
  totalNumberOfRecords = 0; // how many records available totally.
  currentNumberOfRecords = 0; // how many records were loaded.
  continuationToken = null; // for loading more records
  isSuperAdmin = false;
  editEnabled = false;
  recordEditEnabled = false;
  createEnabled = false;
  SuperAdminListener;
  deleteContacts = new Array();
  deletePhones = new Array();
  deleteAttachments = new Array();
  searchTitle = new FormControl('');
  defaultDistanceUnit = 'Foot';
  /* istanbul ignore next */
  filteredTitles$ = this.searchTitle.valueChanges.pipe(
    startWith(''),
    map((value) => (value ? this._filterTitles(value) : this.Records.value))
  );
  searchTitleIsEmpty = this.filteredTitles$.pipe(map((a) => a.length === 0));
  attachmentToBeDeleted: { attachment: any; rowIndex: number; };
  getAttachmentCall: any;
  isGoingOn: boolean;
  HazardTypesSelect: any[];

  // use for searching record based on title name
  /* istanbul ignore next */
  private _filterTitles(filterValue: string): any[] {
    return this.Records.value.filter((opt) => opt.RecordTitle.toLowerCase().includes(filterValue.toLowerCase()));
  }
  getPremiseHazardTypeCode(idOfType) {
    const data = this.HazardTypes.find((ele) => ele.externalId === idOfType);
    if (data !== undefined) {
      return data.code;
    }
    return '';
  }
  getPremiseHazardRecordFormControls() {
    return this.premiseHazardRecordForm.controls.Attachment;
  }
  jumpToSearchedRow(PremiseHazardInfoKey) {
    this.rowindex = (this.Records.value as Array<any>).findIndex(
      (item) => item.ExternalId === PremiseHazardInfoKey
    );
    const record = this.Records.controls[this.rowindex];
    this.load_record_row(record, this.rowindex);
  }
  // For current record, add new contact.
  addNewContact() {
    const newcontact: any = {};
    newcontact.ContactName = { ContactName: '' };
    newcontact.PhoneNumber = new Array();
    const contactarray = this.premiseHazardRecordForm.get('Contact').value;
    contactarray.push(newcontact);
  }
  // add attachment, at the same time, change the page height
  addAttachment() {
    (this.premiseHazardRecordForm.get('Attachment') as FormArray).push(
      new FormGroup({
        Size: new FormControl(0),
        Title: new FormControl(''),
        Description: new FormControl(''),
        FileUploadPath: new FormControl(),
      })
    );
    const currentheight = parseInt(document.getElementById('content').style.height, 10) + 50;
    document.getElementById('content').style.height = currentheight.toString().concat('px');
  }

  // index of contactarray
  deletecontact(index) {
    const contactarray = this.premiseHazardRecordForm.get('Contact').value;
    /* istanbul ignore else */
    if (contactarray[index].ContactName.PremiseHazardInfoContactKey !== undefined) {
      this.deleteContacts.push(contactarray[index].ContactName.PremiseHazardInfoContactKey);
    }
    contactarray.splice(index, 1);
  }

  // i: index of contactarray, j:index of phone list for specific contact
  deletecontactphone(i, j) {
    const phonearray = this.premiseHazardRecordForm.get('Contact').value[i].PhoneNumber;
    /* istanbul ignore else */
    if (phonearray[j].PremiseHazardInfoContactPhoneKey !== undefined) {
      this.deletePhones.push(phonearray[j].PremiseHazardInfoContactPhoneKey);
    }
    phonearray.splice(j, 1);
  }

  // check whether the contact list is valid
  comfirmnewcontact() {
    for (let i = 0; i < this.premiseHazardRecordForm.get('Contact').value.length; i++) {
      if (
        (document.getElementById('ContactName' + String(i)) as HTMLInputElement).value.length === 0 ||
        !(document.getElementById('ContactName' + String(i)) as HTMLInputElement).value
      ) {
        this.toastService.warning('All contacts need name, please check your No.' + (i + 1).toString() + ' contact');
        return false;
      } else {
        this.premiseHazardRecordForm.get('Contact').value[i].ContactName.ContactName = (
          document.getElementById('ContactName' + String(i)) as HTMLInputElement
        ).value;
        for (let j = 0; j < this.premiseHazardRecordForm.get('Contact').value[i].PhoneNumber.length; j++) {
          const indexofphone = 'contact' + i.toString() + '_phone' + j.toString();
          if (
            (document.getElementById(indexofphone) as HTMLInputElement).value.length === 0 ||
            !(document.getElementById(indexofphone) as HTMLInputElement).value
          ) {
            this.deletecontactphone(i, j);
          } else {
            this.premiseHazardRecordForm.get('Contact').value[i].PhoneNumber[j].PhoneNumber = (
              document.getElementById(indexofphone) as HTMLInputElement
            ).value;
          }
        }
      }
    }
    return true;
  }
  // i: index of contact list
  addcontactphone(i) {
    const phonearray = this.premiseHazardRecordForm.get('Contact').value[i].PhoneNumber;
    // tslint:disable-next-line:no-angle-bracket-type-assertion
    const index = 'phonebuffer' + String(i);
    const inputValue = (document.getElementById(index) as HTMLInputElement).value;
    (document.getElementById(index) as HTMLInputElement).value = '';
    if (inputValue === '' || inputValue.length === 0) {
      this.toastService.warning('Please type in phone number');
    } else {
      const newcontactphone: any = {};
      newcontactphone.PhoneNumber = inputValue;
      phonearray.push(newcontactphone);
    }
  }

  create_schema() {
    this.AttachmentsURI = new Array<any>(); // for saving the return URI from CCDrive
    this.Records = new FormArray([]);
    this.premiseHazardRecordForm = new FormGroup({
      AgencyId: new FormControl(''),
      TenantKey: new FormControl(''),
      CustomerId: new FormControl(''),
      IsReviewRequired: new FormControl(true),
      ExternalId: new FormControl(null),
      PremiseHazardTypeKey: new FormControl(null, [Validators.required]),
      IsTemporary: new FormControl('false', [Validators.required]),
      RecordTitle: new FormControl('', [Validators.required]),
      PriorityType: new FormControl(null, [Validators.required]),
      AssociateRecordType: new FormControl('Location'),
      HazardArea: new FormControl(''),
      Location: new FormControl('', [Validators.required]),
      LocName: new FormControl(''),
      City: new FormControl(''),
      Building: new FormControl(''),
      AptUnit: new FormControl(''),
      Floor: new FormControl(''),
      Subdivision: new FormControl(''),
      ZipCode: new FormControl(''),
      AuthorizingPersonnel: new FormControl(''),
      PurgeType: new FormControl('Do not purge'),
      PurgeDate: new FormControl(''),
      OwnerName: new FormControl(''),
      OwnerAddress: new FormControl(''),
      OwnerPhone: new FormControl(''),
      Comments: new FormControl(''),
      Attachment: new FormArray([
        new FormGroup({
          Size: new FormControl(0),
          Title: new FormControl(''),
          Description: new FormControl(''),
          FileUploadPath: new FormControl(),
        }),
      ]),
      Latitude: new FormControl('', [Validators.required]),
      Longitude: new FormControl('', [Validators.required]),
      // tslint:disable-next-line:no-string-literal
      Contact: new FormArray([]),
    });
  }

  // tslint:disable-next-line:variable-name
  constructor(
    public rest: RestService,
    private router: Router,
    public toastService: ToastService,
    // tslint:disable-next-line:align
    private contentModalService: ContentModalService,
    private modalService: ModalService,
    private http: HttpClient,
  ) { }

  ngOnInit(): void {
    this.create_schema();
    this.ReadyListener = this.rest.Ready.subscribe((ready) => {
      if (ready === true) {
        this.CustomerIDListener = this.rest.currCustomer.subscribe((customer) => {
          this.currentCustomer = customer;
        });
        this.SuperAdminListener = this.rest.isCCAdminSuperAdmin.subscribe((a) => {
          this.isSuperAdmin = a;
        });
        this.DefaultDistanceUnitListener = this.rest.DefaultDistanceUnit.subscribe((defaultDistanceUnit) => {
          this.defaultDistanceUnit = defaultDistanceUnit;
        });
        this.switchAgencyEventListener = this.rest.currentAgency.subscribe((agencyName) => {
          this.currentAgency = agencyName;
          if (!this.beSwitched) {
            this.beSwitched = true;
            this.loadRecords(this.currentCustomer, this.currentAgency);
          } else {
            this.switchAgency(this.currentCustomer, this.currentAgency);
          }
        });
        this.loadTenantConfigurations(this.currentCustomer);
      }
    });
  }

  ngOnDestroy() {
    if (this.switchAgencyEventListener !== undefined) {
      this.switchAgencyEventListener.unsubscribe();
    }
    /* istanbul ignore next */
    if (this.ReadyListener !== undefined) {
      this.ReadyListener.unsubscribe();
    }
    if (this.CustomerIDListener !== undefined) {
      this.CustomerIDListener.unsubscribe();
    }
    if (this.SuperAdminListener !== undefined) {
      this.SuperAdminListener.unsubscribe();
    }
    if (this.DefaultDistanceUnitListener !== undefined) {
      this.DefaultDistanceUnitListener.unsubscribe();
    }
    if (this.getAttachmentCall !== undefined) {
      this.getAttachmentCall.unsubscribe();
    }
  }

  public initializeFormGroup() {
    this.rowindex = 0;
    this.load_record_row(this.Records.controls[this.rowindex], this.rowindex);
    /* istanbul ignore else */
    if (this.premiseHazardRecordForm.get('AssociateRecordType').value === 'Location') {
      this.premiseHazardRecordForm.get('HazardArea').enable();
    }
  }

  public async loadhazardtypes(CustomerId, AgencyId, continuationToken) {
    if(!this.HazardTypes){
      this.HazardTypes = new Array();
      this.HazardTypesSelect = new Array();
    }
    await this.rest
      .getTypesProximitySchema(CustomerId, AgencyId, continuationToken)
      .toPromise()
      .then(
        (data) => {
          /* istanbul ignore else */
          if (data !== null) {
            // tslint:disable-next-line:prefer-const
            let typeProximitySettings = [];
            typeProximitySettings = data.premiseHazardTypes.map((e) => {
              e.IsSharedData = e.ccCustomer !== this.currentCustomer;
              return e;
            });
            for (const i of typeProximitySettings) {
              this.HazardTypes.push(i);
              if (!i.IsSharedData) {
                this.HazardTypesSelect.push(i);
              }
            }
            if(data.continuationToken) {
              this.loadhazardtypes(CustomerId, AgencyId, data.continuationToken);
            }
            this.HazardTypesSelect.sort((a, b) => {
              if (a.code < b.code) {
                return -1;
              }
              if (a.code > b.code) {
                return 1;
              }
              return 0;
            });
          }
        },
        (err) => {
          if (err.status === 400 || err.status === 500) {
            this.toastService.error('Fail to load premise hazard types! - ' + err.error);
          } else {
            /* istanbul ignore else */
            if (err.status !== 404) {
              this.toastService.error('Fail to load premise hazard types! - ' + err.statusText);
            }
            console.log(err);
          }
        }
      );
  }

  // The initialization data load function, only load first 30 data (or smaller than 30)
  public async loadRecords(CustomerId, AgencyId) {
    this.loadhazardtypes(CustomerId, AgencyId, null);
    this.rest
      .getPremiseHazardRecords(CustomerId, AgencyId)
      .toPromise()
      .then(
        (data) => {
          // tslint:disable-next-line:prefer-const
          data.premiseHazards;
          this.totalNumberOfRecords = data.premiseHazards.length;
          for (const premiseHazardItem of data.premiseHazards) {
            const recordOfEachRow = this.buildRecord(premiseHazardItem);
            this.currentNumberOfRecords++;
            this.continuationToken = data.continuationToken;
            this.Records.push(recordOfEachRow);
          }

          this.Records.patchValue(this.sortByShared(this.Records.value));
          
          this.initializeFormGroup();
        },
        (err) => {
          if (err.status === 400 || err.status === 500) {
            this.toastService.error('Fail to load premise hazard records! - ' + err.error);
          } else {
            /* istanbul ignore else */
            if (err.status !== 404) {
              this.toastService.error('Fail to load premise hazard records! - ' + err.statusText);
            }
            console.log(err);
          }
        }
      );
  }

  private sortByShared(recordsValues) {
    return recordsValues.sort((obj1, obj2) => {
      if (obj1.IsSharedData && !obj2.IsSharedData) {
        return 1;
      }
      if (!obj1.IsSharedData && obj2.IsSharedData) {
        return -1;
      }

      return 0;
    })
  }

  private buildRecord(phzRecord) {
    const associateRecordType = phzRecord.address == null ? 'Area' : 'Location';
    const recordOfEachRow = new FormGroup({
      PhzId: new FormControl(phzRecord.id),
      ExternalId: new FormControl(phzRecord.externalId),
      PremiseHazardTypeKey: new FormControl(phzRecord.type?.externalId),
      AgencyId: new FormControl(phzRecord.ccAgency),
      CustomerId: new FormControl(this.currentCustomer),
      TenantKey: new FormControl(phzRecord.externalTenantId),
      IsTemporary: new FormControl(phzRecord.isTemporary.toString()),
      IsReviewRequired: new FormControl(phzRecord.isReviewRequired),
      RecordTitle: new FormControl(phzRecord.recordTitle),
      PriorityType: new FormControl(phzRecord.priority),
      AssociateRecordType: new FormControl(associateRecordType),
      IsSharedData: new FormControl(phzRecord.ccCustomer !== this.currentCustomer),
      CcCustomer: new FormControl(phzRecord.ccCustomer),
      Address: new FormGroup({
        HouseAddress: new FormControl(phzRecord.address?.houseAddress),
        CommonPlace: new FormControl(phzRecord.address?.commonPlace),
        City: new FormControl(phzRecord.address?.city),
        BuildingNumber: new FormControl(phzRecord.address?.buildingNumber),
        ApartmentNumber: new FormControl(phzRecord.address?.apartmentNumber),
        Floor: new FormControl(phzRecord.address?.floor),
        Subdivision: new FormControl(phzRecord.address?.subdivision),
        PostalCode: new FormControl(phzRecord.address?.postalCode),
        AddressKey: new FormControl(phzRecord.address?.id),
        AgencyId: new FormControl(phzRecord.ccAgencyId),
        CrossStreetPrefix: new FormControl(phzRecord.address?.crossStreetPrefix),
        CrossStreetSuffix: new FormControl(phzRecord.address?.crossStreetSuffix),
        CrossStreetType: new FormControl(phzRecord.address?.crossStreetType),
        Description: new FormControl(phzRecord.address?.description),
        ExtendedPostalCode: new FormControl(phzRecord.address?.extendedPostalCode),
        HighCrossStreet: new FormControl(phzRecord.address?.highCrossStreet),
        Intersection: new FormControl(phzRecord.address?.intersection),
        Latitude: new FormControl(phzRecord.address?.latitude),
        Longitude: new FormControl(phzRecord.address?.longitude),
        LowCrossStreet: new FormControl(phzRecord.address?.lowCrossStreet),
        RouteLatitude: new FormControl(phzRecord.address?.routeLatitude),
        RouteLongitude: new FormControl(phzRecord.address?.routeLongitude),
        State: new FormControl(phzRecord.address?.state),
        Street1: new FormControl(phzRecord.address?.street1),
        Street2: new FormControl(phzRecord.address?.street2),
        StreetPrefix: new FormControl(phzRecord.address?.streetPrefix),
        StreetSuffix: new FormControl(phzRecord.address?.streetSuffix),
        StreetType: new FormControl(phzRecord.address?.streetType),
        TenantKey: new FormControl(phzRecord.address?.tenantKey),
        IsVerified: new FormControl(phzRecord.address?.isVerified),
      }),
      AuthorizingPersonnel: new FormControl(phzRecord.authorizingPersonnel),
      PurgeType: new FormControl(phzRecord.purgeType),
      PurgeDate: new FormControl(phzRecord.purgeDate),
      OwnerName: new FormControl(phzRecord.ownerName),
      OwnerAddress: new FormControl(phzRecord.ownerAddress),
      OwnerPhone: new FormControl(phzRecord.ownerPhone),
      Comments: new FormControl(phzRecord.comments),
      Longitude: new FormControl(phzRecord.address?.longitude),
      Latitude: new FormControl(phzRecord.address?.latitude),
      ContactList: new FormArray([]),
      ExistingAttachment: new FormArray([])
    });
    const contactArray = new FormArray([]);
    phzRecord?.contacts?.forEach((person) => {
      const phoneArrayEach = new FormArray([]);
      (person.devices as Array<any>).forEach((phone) => {
        phoneArrayEach.push(
          new FormGroup({
            PremiseHazardInfoContactPhoneKey: new FormControl(phone.externalId),
            PhoneNumber: new FormControl(phone.address),
          })
        );
      });
      const nameEach = new FormGroup({
        ContactName: new FormControl(person.name),
        PremiseHazardInfoContactKey: new FormControl(person.externalId),
      });
      const ContactEach = new FormGroup({
        ContactName: nameEach,
        PhoneNumber: phoneArrayEach,
      });
      contactArray.push(ContactEach);
    });
    recordOfEachRow.setControl('ContactList', contactArray);
    const attachmentEachRecord = new FormArray([]);
    /* istanbul ignore else */
    if (phzRecord.attachments) {
      phzRecord.attachments.forEach((attachment) => {
        attachmentEachRecord.push(new FormControl(attachment));
      });
      // for calling CCDrive, we only need unique Path of CCDrive
    }
    recordOfEachRow.setControl('ExistingAttachment', attachmentEachRecord);
    return recordOfEachRow;
  }

  // i: selected object, j: current index of record list
  public load_record_row(phzRecord, currentRowIndex) {
    this.recordEditEnabled = this.editEnabled && !phzRecord.value.IsSharedData;
    // after selecting a new record but not created yet or when initiliazation period
    /* istanbul ignore else */
    if (this.premiseHazardRecordForm.get('PremiseHazardTypeKey').value == null) {
      // click a record which is not created yet,if it is under initiliazation period, then i.get('PremiseHazardInfoKey') alwasy be not null
      if ((phzRecord as FormGroup).get('ExternalId') == null) {
        return;
      } else {
        // when j !=0, this means there is a record uncreated but selected, then jump to other record (which index > 0).
        // The information need to be saved.
        if (currentRowIndex !== 0) {
          this.Records.at(0).patchValue({
            ExternalId: this.premiseHazardRecordForm.get('ExternalId').value,
            PremiseHazardTypeKey: this.premiseHazardRecordForm.get('PremiseHazardTypeKey').value,
            IsTemporary: this.premiseHazardRecordForm.get('IsTemporary').value,
            IsReviewRequired: this.premiseHazardRecordForm.get('IsReviewRequired').value,
            RecordTitle: this.premiseHazardRecordForm.get('RecordTitle').value,
            PriorityType: this.premiseHazardRecordForm.get('PriorityType').value,
            AgencyId: this.Records.at(0).controls.AgencyId.value,
            TenantKey: this.Records.at(0).controls.TenantKey.value,
            CustomerId: this.Records.at(0).controls.CustomerId.value,
            AuthorizingPersonnel: this.premiseHazardRecordForm.get('AuthorizingPersonnel').value,
            PurgeType: this.premiseHazardRecordForm.get('PurgeType').value,
            PurgeDate: this.premiseHazardRecordForm.get('PurgeDate').value,
            OwnerName: this.premiseHazardRecordForm.get('OwnerName').value,
            OwnerAddress: this.premiseHazardRecordForm.get('OwnerAddress').value,
            OwnerPhone: this.premiseHazardRecordForm.get('OwnerPhone').value,
            Comments: this.premiseHazardRecordForm.get('Comments').value,
            Longitude: this.premiseHazardRecordForm.get('Longitude').value,
            Latitude: this.premiseHazardRecordForm.get('Latitude').value,
          });
        }
      }
    }
    this.rowindex = currentRowIndex;
    this.premiseHazardRecordForm.reset();
    while (this.deleteAttachments.length) {
      this.deleteAttachments.pop();
    }
    while (this.deleteContacts.length) {
      this.deleteContacts.pop();
    }
    while (this.deletePhones.length) {
      this.deletePhones.pop();
    }
    this.premiseHazardRecordForm.removeControl('Contact');
    this.premiseHazardRecordForm.patchValue({
      ExternalId: phzRecord.get('ExternalId').value,
      PremiseHazardInfoKey: phzRecord.get('PremiseHazardInfoKey') == null ? null : phzRecord.get('PremiseHazardInfoKey').value,
      PremiseHazardTypeKey: phzRecord.get('PremiseHazardTypeKey') == null ? null : phzRecord.get('PremiseHazardTypeKey').value,
      AgencyId: phzRecord.get('AgencyId') == null ? null : phzRecord.get('AgencyId').value,
      TenantKey: phzRecord.get('TenantKey') == null ? null : phzRecord.get('TenantKey').value,
      CustomerId: phzRecord.get('CustomerId') == null ? null : phzRecord.get('CustomerId').value,
      IsTemporary: phzRecord.get('IsTemporary') == null ? null : phzRecord.get('IsTemporary').value,
      IsReviewRequired: phzRecord.get('IsReviewRequired') == null ? null : phzRecord.get('IsReviewRequired').value,
      RecordTitle: phzRecord.get('RecordTitle') == null ? null : phzRecord.get('RecordTitle').value,
      PriorityType: phzRecord.get('PriorityType') == null ? null : phzRecord.get('PriorityType').value,
      AssociateRecordType: phzRecord.get('AssociateRecordType') == null ? null : phzRecord.get('AssociateRecordType').value,
      // HazardArea: i.get('HazardArea').value,
      Location: phzRecord.get('Address').value.HouseAddress == null ? null : phzRecord.get('Address').value.HouseAddress,
      LocName: phzRecord.get('Address').value.CommonPlace == null ? null : phzRecord.get('Address').value.CommonPlace,
      City: phzRecord.get('Address').value.City == null ? null : phzRecord.get('Address').value.City,
      Building: phzRecord.get('Address').value.BuildingNumber == null ? null : phzRecord.get('Address').value.BuildingNumber,
      AptUnit: phzRecord.get('Address').value.ApartmentNumber == null ? null : phzRecord.get('Address').value.ApartmentNumber,
      Floor: phzRecord.get('Address').value.Floor == null ? null : phzRecord.get('Address').value.Floor,
      Subdivision: phzRecord.get('Address').value.Subdivision == null ? null : phzRecord.get('Address').value.Subdivision,
      ZipCode: phzRecord.get('Address').value.PostalCode == null ? null : phzRecord.get('Address').value.PostalCode,
      AuthorizingPersonnel: phzRecord.get('AuthorizingPersonnel') == null ? null : phzRecord.get('AuthorizingPersonnel').value,
      PurgeType: phzRecord.get('PurgeType') == null ? null : phzRecord.get('PurgeType').value,
      PurgeDate: phzRecord.get('PurgeDate') == null ? null : phzRecord.get('PurgeDate').value,
      OwnerName: phzRecord.get('OwnerName') == null ? null : phzRecord.get('OwnerName').value,
      OwnerAddress: phzRecord.get('OwnerAddress') == null ? null : phzRecord.get('OwnerAddress').value,
      OwnerPhone: phzRecord.get('OwnerPhone') == null ? null : phzRecord.get('OwnerPhone').value,
      Comments: phzRecord.get('Comments') == null ? null : phzRecord.get('Comments').value,
      Latitude: phzRecord.get('Latitude') == null ? null : phzRecord.get('Latitude').value,
      Longitude: phzRecord.get('Longitude') == null ? null : phzRecord.get('Longitude').value,
    });
    const contactlist = new FormArray([]);
    /* istanbul ignore else */
    if (this.Records.value[currentRowIndex].ContactList.length !== 0) {
      for (const con of this.Records.value[currentRowIndex].ContactList) {
        const contact = new FormGroup({
          ContactName: new FormGroup({
            ContactName: new FormControl(con.ContactName.ContactName),
            PremiseHazardInfoContactKey: new FormControl(con.ContactName.PremiseHazardInfoContactKey),
          }),
          PhoneNumber: new FormArray([]),
        });
        for (const phone of con.PhoneNumber) {
          (contact.controls.PhoneNumber as FormArray).push(
            new FormGroup({
              PhoneNumber: new FormControl(phone.PhoneNumber),
              PremiseHazardInfoContactPhoneKey: new FormControl(phone.PremiseHazardInfoContactPhoneKey),
            })
          );
        }
        contactlist.push(contact);
      }
    }
    this.premiseHazardRecordForm.setControl('Contact', contactlist);

    // load attachments, need to clear the attachments first
    while (this.AttachmentsURI.length) {
      this.AttachmentsURI.pop();
    }
    /* istanbul ignore else */
    if (phzRecord.get('ExistingAttachment') != null && phzRecord.get('ExistingAttachment').value.length !== 0) {
      this.getAttachmentCall && this.getAttachmentCall.unsubscribe();
      for (let attachment of phzRecord.get('ExistingAttachment').value) {
        this.getAttachmentCall = this.rest.getAttachmentById(phzRecord.get('PhzId').value, attachment.id, phzRecord.get('CcCustomer').value, phzRecord.get('AgencyId').value)
          .subscribe({
            next: (res) => {
              attachment.uri = res['uri'];
              attachment.uri_inline = res['uri_inline'];
              if (this.AttachmentsURI.indexOf(attachment) === -1) {
                this.AttachmentsURI.push(attachment);
              }
            },
            error: (e) => {
              if (e.error.Status !== 403 && this.AttachmentsURI.indexOf(attachment) === -1) {
                this.AttachmentsURI.push(attachment);
              }
              console.error(e);
            }
          });
      }
    }
  }

  public callGeoverificationAPI() {
    (document.getElementById('VerifySpinner') as HTMLElement).hidden = false;
    const locationinformation = {
      address: this.premiseHazardRecordForm.get('Location').value,
      locationName: this.premiseHazardRecordForm.get('LocName').value,
      building: this.premiseHazardRecordForm.get('Building').value,
      floor: this.premiseHazardRecordForm.get('Floor').value,
      city: this.premiseHazardRecordForm.get('City').value,
      zipCode: this.premiseHazardRecordForm.get('ZipCode').value,
      subdivision: this.premiseHazardRecordForm.get('Subdivision').value,
      apartment: this.premiseHazardRecordForm.get('AptUnit').value,
    };
    this.rest
      .callGeoverfication(locationinformation, this.currentCustomer, this.currentAgency)
      .toPromise()
      .then(
        (res) => {
          (document.getElementById('VerifySpinner') as HTMLElement).hidden = true;
          console.log(res); // original match locations
          this.matchLocations = res;
          this.matchLocations = this.matchLocations.filter(this.filter_matchlocations);
          if (this.matchLocations.length === 0) {
            this.noMatchLocation();
          } else {
            // tslint:disable-next-line:prefer-const
            // filter data, those locations without x or y and feature ID would be eliminated
            this.openContentModal(this.geoverification, 0);
          }
        },
        (err: HttpErrorResponse) => {
          (document.getElementById('VerifySpinner') as HTMLElement).hidden = true;
          console.log(err);
          this.toastService.error('Unable to verify address.' + err.error);
        }
      );
  }

  // every record should have x and y, or feature id should be provided to further call geocode endpoint
  public filter_matchlocations(ele, index, array) {
    return (
      (ele.matchLocation.x !== (0 && null) && ele.matchLocation.y !== (0 && null)) ||
      ele.matchLocation.featureId != null
    );
  }

  public addnewrow() {
    if (this.Records.length === 0 || this.Records.at(0).value.ExternalId) {
      this.recordEditEnabled = true;
      const i = new FormGroup({
        IsSharedData: new FormControl(false),
        CcCustomer: new FormControl(this.currentCustomer),
        AgencyId: new FormControl(this.currentAgency),
        CustomerId: new FormControl(this.currentCustomer),
        TenantKey: new FormControl(0),
        PremiseHazardTypeKey: new FormControl(''),
        IsTemporary: new FormControl('false'),
        IsReviewRequired: new FormControl(true),
        RecordTitle: new FormControl(''),
        PriorityType: new FormControl(null),
        AuthorizingPersonnel: new FormControl(''),
        PurgeType: new FormControl('Do not purge'),
        PurgeDate: new FormControl(''),
        OwnerName: new FormControl(''),
        OwnerAddress: new FormControl(''),
        OwnerPhone: new FormControl(''),
        Comments: new FormControl(''),
        Latitude: new FormControl(''),
        Longitude: new FormControl(''),
        Attachment: new FormArray([
          new FormGroup({
            Size: new FormControl(0),
            Title: new FormControl(''),
            Description: new FormControl(''),
            FileUploadPath: new FormControl(),
          }),
        ]), // just for updating, existing attachments would be saved in existingAttachments
        ContactList: new FormArray([]),
        ExistingAttachment: new FormArray([]),
        Address: new FormGroup({
          AgencyId: new FormControl(this.currentAgency, [Validators.required]),
          CustomerId: new FormControl(this.currentCustomer, [Validators.required]),
          TenantKey: new FormControl(0),
          HouseAddress: new FormControl('', [Validators.required]),
          CommonPlace: new FormControl(''),
          City: new FormControl(''),
          BuildingNumber: new FormControl(''),
          ApartmentNumber: new FormControl(''),
          Floor: new FormControl(''),
          Subdivision: new FormControl(''),
          PostalCode: new FormControl(''),
          IsVerified: new FormControl(''),
          Longitude: new FormControl('', [Validators.required]),
          Latitude: new FormControl('', [Validators.required]),
          Description: new FormControl(''),
          HighCrossStreet: new FormControl(''),
          LowCrossStreet: new FormControl(''),
          State: new FormControl(''),
          Intersection: new FormControl(''),
          RouteLatitude: new FormControl(''),
          RouteLongitude: new FormControl(''),
        }),
      });
      (this.Records as FormArray).insert(0, i);
      this.rowindex = 0;
      this.premiseHazardRecordForm.reset();
      while (this.deleteContacts.length) {
        this.deleteContacts.pop();
      }
      while (this.deletePhones.length) {
        this.deletePhones.pop();
      }
      while (this.deleteAttachments.length) {
        this.deleteAttachments.pop();
      }
      this.premiseHazardRecordForm.controls.Contact = new FormArray([]);
      while (this.AttachmentsURI.length) {
        this.AttachmentsURI.pop();
      }
      this.premiseHazardRecordForm.patchValue({
        AssociateRecordType: 'Location',
        PurgeType: 'Do not purge',
        IsTemporary: 'false',
        IsReviewRequired: true,
      });
    }
  }

  public async saveUpdate() {
    this.Records.at(this.rowindex).patchValue({
      ExternalId: this.premiseHazardRecordForm.get('ExternalId').value,
      PremiseHazardTypeKey: this.premiseHazardRecordForm.get('PremiseHazardTypeKey').value,
      IsTemporary: this.premiseHazardRecordForm.get('IsTemporary').value,
      IsReviewRequired: this.premiseHazardRecordForm.get('IsReviewRequired').value,
      RecordTitle: this.premiseHazardRecordForm.get('RecordTitle').value,
      PriorityType: this.premiseHazardRecordForm.get('PriorityType').value,
      TenantKey: this.Records.at(this.rowindex).controls.TenantKey.value,
      AuthorizingPersonnel: this.premiseHazardRecordForm.get('AuthorizingPersonnel').value,
      PurgeType: this.premiseHazardRecordForm.get('PurgeType').value,
      PurgeDate: this.premiseHazardRecordForm.get('PurgeDate').value,
      OwnerName: this.premiseHazardRecordForm.get('OwnerName').value,
      OwnerAddress: this.premiseHazardRecordForm.get('OwnerAddress').value,
      // CountryCode: this.premiseHazardRecordForm.get('CountryCode').value,
      OwnerPhone: this.premiseHazardRecordForm.get('OwnerPhone').value,
      Comments: this.premiseHazardRecordForm.get('Comments').value,
      Longitude: this.premiseHazardRecordForm.get('Longitude').value,
      Latitude: this.premiseHazardRecordForm.get('Latitude').value,
    });
    /* istanbul ignore else */
    if (this.comfirmnewcontact()) {
      // for the contact and contact phones
      const createcontactmodel = new FormArray([]);
      for (let i = 0; i < this.premiseHazardRecordForm.get('Contact').value.length; i++) {
        if (!this.premiseHazardRecordForm.get('Contact').value[i].ContactName.PremiseHazardInfoContactKey) {
          this.premiseHazardRecordForm.get('Contact').value[i].ContactName.PremiseHazardInfoContactKey = 0;
        }
        createcontactmodel.push(
          new FormGroup({
            ContactName: new FormGroup({
              ContactName: new FormControl(
                this.premiseHazardRecordForm.get('Contact').value[i].ContactName.ContactName
              ),
              PremiseHazardInfoContactKey: new FormControl(
                this.premiseHazardRecordForm.get('Contact').value[i].ContactName.PremiseHazardInfoContactKey
              ),
            }),
            PhoneNumber: new FormArray([]),
          })
        );

        for (const j of this.premiseHazardRecordForm.get('Contact').value[i].PhoneNumber) {
          if (!j.PremiseHazardInfoContactPhoneKey) {
            j.PremiseHazardInfoContactPhoneKey = 0;
          }
          ((createcontactmodel.controls[i] as FormGroup).controls.PhoneNumber as FormArray).push(
            new FormGroup({
              PhoneNumber: new FormControl(j.PhoneNumber),
              PremiseHazardInfoContactPhoneKey: new FormControl(j.PremiseHazardInfoContactPhoneKey),
            })
          );
        }
      }
      console.log(createcontactmodel);
      this.Records.at(this.rowindex).setControl('ContactList', createcontactmodel);
      this.Records.at(this.rowindex).removeControl('ExistingAttachment');
      // we dont need ExistingAttachment when we update, it uses for saving existing CCDrive Path
      // check the delete contacts and phones
      const deletePhonesFormarray = new FormArray([]);
      const deleteContactsFormarray = new FormArray([]);
      const deleteAttachmentsFormarray = new FormArray([]);
      while (this.deleteContacts.length) {
        deleteContactsFormarray.push(new FormControl(this.deleteContacts.pop()));
      }
      while (this.deletePhones.length) {
        deletePhonesFormarray.push(new FormControl(this.deletePhones.pop()));
      }
      while (this.deleteAttachments.length) {
        deleteAttachmentsFormarray.push(new FormControl(this.deleteAttachments.pop()));
      }
      this.Records.at(this.rowindex).setControl('Delete_Phones', deletePhonesFormarray);
      this.Records.at(this.rowindex).setControl('Delete_Contacts', deleteContactsFormarray);
      this.Records.at(this.rowindex).setControl('Delete_Attachments', deleteAttachmentsFormarray);
      // this.Records.at(this.rowindex).setControl("ContactList",this.premiseHazardRecordForm.get('Contact'))
      const updateRecord = this.Records.at(this.rowindex).value;
      // need to check whether user has changed the verified address, it is avoided.
      /* istanbul ignore if */
      if (updateRecord.Address.ApartmentNumber !== this.premiseHazardRecordForm.get('AptUnit').value) {
        updateRecord.Address.IsVerified = false;
      }
      /* istanbul ignore if */
      if (updateRecord.Address.BuildingNumber !== this.premiseHazardRecordForm.get('Building').value) {
        updateRecord.Address.IsVerified = false;
      }
      /* istanbul ignore if */
      if (updateRecord.Address.City !== this.premiseHazardRecordForm.get('City').value) {
        updateRecord.Address.IsVerified = false;
      }
      /* istanbul ignore if */
      if (updateRecord.Address.CommonPlace !== this.premiseHazardRecordForm.get('LocName').value) {
        updateRecord.Address.IsVerified = false;
      }
      /* istanbul ignore if */
      if (updateRecord.Address.Floor !== this.premiseHazardRecordForm.get('Floor').value) {
        updateRecord.Address.IsVerified = false;
      }
      /* istanbul ignore if */
      if (updateRecord.Address.Subdivision !== this.premiseHazardRecordForm.get('Subdivision').value) {
        updateRecord.Address.IsVerified = false;
      }
      /* istanbul ignore if */
      if (updateRecord.Address.PostalCode !== this.premiseHazardRecordForm.get('ZipCode').value) {
        updateRecord.Address.IsVerified = false;
      }
      /* istanbul ignore if */

      if (
        updateRecord.Address.HouseAddress !== this.premiseHazardRecordForm.get('Location').value &&
        updateRecord.Address.Intersection !== this.premiseHazardRecordForm.get('Location').value
      ) {
        updateRecord.Address.IsVerified = false;
      }

      // would add agency information and customer information there. There are two reason for adding agency information,
      // the first one is when create data, agency information should be set to imply who create it
      // the second is if a agency is not the creator(could be), then need to imply who make this change.

      /* istanbul ignore else */
      if (updateRecord.AgencyId === '') {
        updateRecord.AgencyId = this.currentAgency;
      }
      /* istanbul ignore else */
      if (updateRecord.CustomerId === '') {
        updateRecord.CustomerId = this.currentCustomer;
      }
      // should imply who make this update
      // if it is made by super admin, would be set as "motorola-solutions"
      if (this.isSuperAdmin) {
        updateRecord.LastUpdateUserAgencyId = 'motorola-solutions';
      } else {
        updateRecord.LastUpdateUserAgencyId = this.currentAgency;
      }
      updateRecord.LastUpdateUserId = sessionStorage.getItem('user');
      console.log(updateRecord);
      const validity = this.ValidateRecordInput(updateRecord);

      if (validity[0] === true) {
        if (updateRecord.Address.IsVerified === true) {

          if (!updateRecord.Address.AddressKey) {
            updateRecord.Address.AddressKey = Guid.create().toString();
          }

          await this.rest.upsertAddress(updateRecord.Address, this.currentCustomer, this.currentAgency).toPromise().then(
            (res) => {
              console.log(res);
              this.toastService.success('Saved address successfully!', undefined, {
                autoDismiss: true && 3000,
              });
            },
            (err) => {
              console.log(err);
              this.toastService.error('Failed to save address - ' + err.statusText);
            }
          );

          if (updateRecord.ContactList.length) {
            for (const contact of updateRecord.ContactList) {
              if (contact.ContactName.PremiseHazardInfoContactKey === 0) {
                contact.ContactName.PremiseHazardInfoContactKey = Guid.create().toString();
              }
              for (const phone of contact.PhoneNumber) {
                if (phone.PremiseHazardInfoContactPhoneKey === 0) {
                  phone.PremiseHazardInfoContactPhoneKey = Guid.create().toString();
                }
              }
            }

            await this.rest.upsertContacts(updateRecord.ExternalId, updateRecord.ContactList, this.currentCustomer, this.currentAgency).toPromise().then(
              (res) => {
                console.log(res);
                this.toastService.success('Saved contacts successfully.', undefined, {
                  autoDismiss: true && 3000,
                });
              },
              (err) => {
                console.log(err);
                this.toastService.error('Failed to save contact - ' + err.statusText);
              }
            );
          }

          if (updateRecord.Delete_Contacts.length) {
            await this.rest.deleteContact(updateRecord.ExternalId, updateRecord.Delete_Contacts, this.currentCustomer, this.currentAgency).toPromise().then(
              (res) => {
                console.log(res);
                this.toastService.success('Deleted contacts successfully.', undefined, {
                  autoDismiss: true && 3000,
                });
              },
              (err) => {
                console.log(err);
                this.toastService.error('Failed to delete contact - ' + err.statusText);
              }
            );
          }

          this.rest
            .updatePremiseHazardRecords(updateRecord, this.currentCustomer, this.currentAgency)
            .toPromise()
            .then(
              (res) => {
                this.toastService.success('Updated premise hazard record successfully.', undefined, {
                  autoDismiss: true && 3000,
                });
              },
              (err) => {
                console.log(err);
                this.toastService.error('Update of premise hazard record failed. ');
              }
            );
        } else {
          this.toastService.error('Address should be verified and unchanged, cannot save until verified.');
          updateRecord.Address.IsVerified = true;
        }
      } else {
        this.toastService.error(
          `Refrain from using special characters in textboxes. Please check following fields: ${validity[1].toString()}`
        );
      }
    }
  }
  ValidateRecordInput(RecordToValidate) {
    let validity = true;
    const invalidFields = [];
    if (RecordToValidate.RecordTitle && !RecordToValidate.RecordTitle.match(/^[~()#!`'a-zA-ZÇ-Ñ-./\s,0-9&*$_]+$/)) {
      validity = false;
      invalidFields.push('Record Title');
    }
    if (
      RecordToValidate.AuthorizingPersonnel &&
      !RecordToValidate.AuthorizingPersonnel.match(/^[~()#!`'a-zA-ZÇ-Ñ-./\s,0-9&*$_]+$/)
    ) {
      validity = false;
      invalidFields.push('Authorizing Personnel');
    }
    if (RecordToValidate.OwnerName && !RecordToValidate.OwnerName.match(/^[~()#!`'a-zA-ZÇ-Ñ-./\s,0-9&*$_]+$/)) {
      validity = false;
      invalidFields.push('Owner Name');
    }
    if (RecordToValidate.OwnerPhone && !RecordToValidate.OwnerPhone.match(/^[~()#!`'a-zA-ZÇ-Ñ-./\s,0-9&*$_]+$/)) {
      validity = false;
      invalidFields.push('Owner Phone');
    }
    if (RecordToValidate.OwnerAddress && !RecordToValidate.OwnerAddress.match(/^[~()#!`'a-zA-ZÇ-Ñ-./\s,0-9&*$_]+$/)) {
      validity = false;
      invalidFields.push('Owner Address');
    }
    if (RecordToValidate.Comments && !RecordToValidate.Comments.match(/^[~()#!`'a-zA-ZÇ-Ñ-./\s,0-9&*$_]+$/)) {
      validity = false;
      invalidFields.push('Comments');
    }
    /* istanbul ignore else */
    if (RecordToValidate.ContactList) {
      for (const contact of RecordToValidate.ContactList) {
        if (!contact.ContactName.ContactName.match(/^[~()#!`'a-zA-ZÇ-Ñ-./\s,0-9&*$_]+$/)) {
          validity = false;
          invalidFields.push('Contact List');
        }
        for (const phone of contact.PhoneNumber) {
          if (!phone.PhoneNumber.match(/^[~()#!`'a-zA-ZÇ-Ñ-./\s,0-9&*$_]+$/)) {
            validity = false;
            invalidFields.push('Contact Phone Number');
          }
        }
      }
    }
    return [validity, invalidFields];
  }
  // geoverification pop-up modal, if satisfied returns exisit
  /* istanbul ignore next */
  openContentModal(tpl: TemplateRef<any>, index: number = 0) {
    this.contentModalService.open(tpl, {
      data: { selectedMediaIndex: index },
    });
  }
  /* istanbul ignore next */
  noMatchLocation() {
    this.toastService.warning('No match location');
  }

  // if the record already has x nad y which are not 0, no further called needed
  // without featureId, further call of geocode cannot ba made.
  public confirmlocation(location) {
    if ((location.x === 0 || location.y === 0) && location.featureId) {
      class GeocodeInput {
        houseAddress: string;
        formattedHouseAddressv: string;
        intersection: string;
        commonplace: string;
        commonplaceType: string;
        highStreetNumber: string;
        lowStreetNumber: string;
        highCrossStreet: string;
        lowCrossStreet: string;
        highCrossStreetFormatted: string;
        lowCrossStreetFormatted: string;
        lowCrossBlock: string;
        highCrossBlock: string;
        city: string;
        postalCity: string;
        state: string;
        building: string;
        floor: string;
        apartment: string;
        subdivision: string;
        zipCode: string;
        x: number;
        y: number;
        routeLongitude: number;
        routeLatitude: number;
        description: string;
        realName: string;
        additionalInfo: string;
        featureId: string;
        locationMark: string;
        distance: number;
        direction: string;
        leftSide: string;
        motorwayName: string;
        motorwayJ1: string;
        motorwayJ2: string;
        motorwayDir: string;
        validationLevel: string;
        validationLevelAdditional: string;
        isInTheStreet: string;
        offsetCoordinates: Array<any>;
        housesReversed: string;
      }
      const geocodeinput: GeocodeInput = {
        houseAddress: location.houseAddress,
        formattedHouseAddressv: location.formattedHouseAddressv,
        intersection: location.intersection,
        commonplace: location.commonplace,
        commonplaceType: location.commonplaceType,
        highStreetNumber: location.highStreetNumber,
        lowStreetNumber: location.lowStreetNumber,
        highCrossStreet: location.highCrossStreet,
        lowCrossStreet: location.lowCrossStreet,
        highCrossStreetFormatted: location.highCrossStreetFormatted,
        lowCrossStreetFormatted: location.lowCrossStreetFormatted,
        lowCrossBlock: location.lowCrossBlock,
        highCrossBlock: location.highCrossBlock,
        city: location.city,
        postalCity: location.postalCity,
        state: location.state,
        building: location.building,
        floor: location.floor,
        apartment: location.apartment,
        subdivision: location.subdivision,
        zipCode: location.zipCode,
        x: location.x,
        y: location.y,
        routeLongitude: location.routeLongitude,
        routeLatitude: location.routeLatitude,
        description: location.description,
        realName: location.realName,
        additionalInfo: location.additionalInfo,
        featureId: location.featureId,
        locationMark: location.locationMark,
        distance: location.distance,
        direction: location.direction,
        leftSide: location.leftSide,
        motorwayName: location.motorwayName,
        motorwayJ1: location.motorwayJ1,
        motorwayJ2: location.motorwayJ2,
        motorwayDir: location.motorwayDir,
        validationLevel: location.validationLevel,
        validationLevelAdditional: location.validationLevelAdditional,
        isInTheStreet: location.isInTheStreet,
        offsetCoordinates: location.offsetCoordinates,
        housesReversed: location.houseAddress,
      };
      console.log(geocodeinput);
      class GeocodeOutput {
        x: number;
        y: number;
      }
      this.rest.geocode(geocodeinput, this.currentCustomer, this.currentAgency).subscribe(
        (res: GeocodeOutput) => {
          console.log(res.x, res.y);
          if (res.x === (0 || null) || res.y === (0 || null)) {
            this.toastService.error('Invalid Address');
          } else {
            this.premiseHazardRecordForm.get('Longitude').setValue(res.x);
            this.premiseHazardRecordForm.get('Latitude').setValue(res.y);
            this.premiseHazardRecordForm.get('City').setValue(location.city);
            this.premiseHazardRecordForm.get('Subdivision').setValue(location.subdivision);
            this.premiseHazardRecordForm.get('Floor').setValue(location.floor);
            this.premiseHazardRecordForm.get('Building').setValue(location.building);
            if ((location.houseAddress === null || location.houseAddress === '') && location.commonplace) {
              location.houseAddress = location.commonplace;
            } else if ((location.houseAddress === null || location.houseAddress === '') && location.intersection) {
              location.houseAddress = location.intersection;
            }
            this.premiseHazardRecordForm.get('Location').setValue(location.houseAddress);
            this.premiseHazardRecordForm.get('AptUnit').setValue(location.apartment);
            this.premiseHazardRecordForm.get('ZipCode').setValue(location.zipCode);
            this.premiseHazardRecordForm.get('LocName').setValue(location.commonplace);
            this.Records.controls[this.rowindex].controls.Longitude.setValue(res.x);
            this.Records.controls[this.rowindex].controls.Latitude.setValue(res.y);
            this.Records.controls[this.rowindex].controls.Address.controls.Longitude.setValue(res.x);
            this.Records.controls[this.rowindex].controls.Address.controls.Latitude.setValue(res.y);
            this.Records.controls[this.rowindex].controls.Address.controls.City.setValue(location.city);
            this.Records.controls[this.rowindex].controls.Address.controls.Subdivision.setValue(location.subdivision);
            this.Records.controls[this.rowindex].controls.Address.controls.Floor.setValue(location.floor);
            this.Records.controls[this.rowindex].controls.Address.controls.BuildingNumber.setValue(location.building);
            this.Records.controls[this.rowindex].controls.Address.controls.HouseAddress.setValue(location.houseAddress);
            this.Records.controls[this.rowindex].controls.Address.controls.ApartmentNumber.setValue(location.apartment);
            this.Records.controls[this.rowindex].controls.Address.controls.PostalCode.setValue(location.zipCode);
            this.Records.controls[this.rowindex].controls.Address.controls.CommonPlace.setValue(location.commonplace);
            this.Records.controls[this.rowindex].controls.Address.controls.Description.setValue(location.description);
            this.Records.controls[this.rowindex].controls.Address.controls.HighCrossStreet.setValue(
              location.highCrossStreet
            );
            this.Records.controls[this.rowindex].controls.Address.controls.LowCrossStreet.setValue(
              location.lowCrossStreet
            );
            this.Records.controls[this.rowindex].controls.Address.controls.State.setValue(location.state);
            this.Records.controls[this.rowindex].controls.Address.controls.Intersection.setValue(location.intersection);
            this.Records.controls[this.rowindex].controls.Address.controls.RouteLatitude.setValue(
              location.routeLatitude
            );
            this.Records.controls[this.rowindex].controls.Address.controls.RouteLongitude.setValue(
              location.routeLongitude
            );
            this.Records.controls[this.rowindex].controls.Address.controls.IsVerified.setValue(true);
            this.toastService.success('Verify successfully!', undefined, {
              autoDismiss: true && 3000,
            });
          }
        },
        (err) => {
          console.log(err);
          this.toastService.error('Invalid Address');
        }
      );
    } else {
      // directe set x and y if x and y are not 0 which means valid
      this.premiseHazardRecordForm.get('Longitude').setValue(location.x);
      this.premiseHazardRecordForm.get('Latitude').setValue(location.y);
      this.premiseHazardRecordForm.get('City').setValue(location.city);
      this.premiseHazardRecordForm.get('Subdivision').setValue(location.subdivision);
      this.premiseHazardRecordForm.get('Floor').setValue(location.floor);
      this.premiseHazardRecordForm.get('Building').setValue(location.building);
      if ((location.houseAddress === null || location.houseAddress === '') && location.commonplace) {
        location.houseAddress = location.commonplace;
      } else if ((location.houseAddress === null || location.houseAddress === '') && location.intersection) {
        location.houseAddress = location.intersection;
      }
      this.premiseHazardRecordForm.get('Location').setValue(location.houseAddress);
      this.premiseHazardRecordForm.get('AptUnit').setValue(location.apartment);
      this.premiseHazardRecordForm.get('ZipCode').setValue(location.zipCode);
      this.premiseHazardRecordForm.get('LocName').setValue(location.commonplace);
      this.Records.controls[this.rowindex].controls.Longitude.setValue(location.x);
      this.Records.controls[this.rowindex].controls.Latitude.setValue(location.y);
      this.Records.controls[this.rowindex].controls.Address.controls.Longitude.setValue(location.x);
      this.Records.controls[this.rowindex].controls.Address.controls.Latitude.setValue(location.y);
      this.Records.controls[this.rowindex].controls.Address.controls.City.setValue(location.city);
      this.Records.controls[this.rowindex].controls.Address.controls.Subdivision.setValue(location.subdivision);
      this.Records.controls[this.rowindex].controls.Address.controls.Floor.setValue(location.floor);
      this.Records.controls[this.rowindex].controls.Address.controls.BuildingNumber.setValue(location.building);
      this.Records.controls[this.rowindex].controls.Address.controls.HouseAddress.setValue(location.houseAddress);
      this.Records.controls[this.rowindex].controls.Address.controls.ApartmentNumber.setValue(location.apartment);
      this.Records.controls[this.rowindex].controls.Address.controls.PostalCode.setValue(location.zipCode);
      this.Records.controls[this.rowindex].controls.Address.controls.CommonPlace.setValue(location.commonplace);
      this.Records.controls[this.rowindex].controls.Address.controls.Description.setValue(location.description);
      this.Records.controls[this.rowindex].controls.Address.controls.HighCrossStreet.setValue(location.highCrossStreet);
      this.Records.controls[this.rowindex].controls.Address.controls.LowCrossStreet.setValue(location.lowCrossStreet);
      this.Records.controls[this.rowindex].controls.Address.controls.State.setValue(location.state);
      this.Records.controls[this.rowindex].controls.Address.controls.Intersection.setValue(location.intersection);
      this.Records.controls[this.rowindex].controls.Address.controls.RouteLatitude.setValue(location.routeLatitude);
      this.Records.controls[this.rowindex].controls.Address.controls.RouteLongitude.setValue(location.routeLongitude);
      this.Records.controls[this.rowindex].controls.Address.controls.IsVerified.setValue(true);
      this.toastService.success('Verify successfully!', undefined, {
        autoDismiss: true && 3000,
      });
    }
  }

  /* istanbul ignore next */
  DeleteContactCancel() {
    this.ContactDeleteComfirm.close();
  }
  /* istanbul ignore next */
  DeleteContactConfirm() {
    this.deletecontact(this.deletcontactindex);
    this.ContactDeleteComfirm.close();
  }
  /* istanbul ignore next */
  confirmBeforeDeleteContact(i) {
    this.deletcontactindex = i;
    this.ContactDeleteComfirm = this.modalService.open(this.contactdelete, {
      disableClose: true,
      hasBackdrop: true,
    });
  }

  // eliminate all changes before clicking update button
  public resetrecord() {
    // This means you are resetting an uncreated record
    /* istanbul ignore else */
    if (this.rowindex === 0 && this.Records.controls[this.rowindex].get('PremiseHazardInfoKey') == null) {
      this.premiseHazardRecordForm.reset();
      this.premiseHazardRecordForm.patchValue({
        AssociateRecordType: 'Location',
        PurgeType: 'Do not purge',
        IsTemporary: 'false',
        IsReviewRequired: true,
      });
      // no need to empty deleteAttachment, because there is no attachment when the record is uncreated.
      while (this.deleteContacts.length) {
        this.deleteContacts.pop();
      }
      while (this.deletePhones.length) {
        this.deletePhones.pop();
      }
    } else {
      this.load_record_row(this.Records.controls[this.rowindex], this.rowindex);
    }
    this.toastService.success('Your Record Reset Successfully!', undefined, {
      autoDismiss: true && 3000,
    });
  }

  public deleterecord() {
    const deleteRecord = new FormGroup({
      ExternalId: new FormControl(''),
      AgencyId: new FormControl(''),
      CustomerId: new FormControl(''),
    });
    deleteRecord.setValue({
      ExternalId: this.Records.value[this.rowindex].ExternalId,
      AgencyId: this.currentAgency,
      CustomerId: this.currentCustomer,
    });
    console.log(deleteRecord);
    this.rest
      .deleteSinglePhzRecord(deleteRecord.value)
      .toPromise()
      .then(
        (res) => {
          this.toastService.success('Delete this record successfully!', undefined, {
            autoDismiss: true && 3000,
          });
          this.Records.removeAt(this.rowindex);
          this.currentNumberOfRecords--;
          this.totalNumberOfRecords--;
          /* istanbul ignore else */
          if (this.Records.length !== 0) {
            this.load_record_row(this.Records.controls[0], 0);
          }
        },
        (err) => {
          if (err.status === 401) {
            this.router.navigate(['/auth_error']);
          }
          if (err.status === 404) {
            this.router.navigate(['/access_error']);
          }
          console.log(err);
          this.toastService.error('This record delete fails!');
        }
      );
  }

  /* istanbul ignore next */
  DeletePHZRecordCancel() {
    this.PHZRecordDeleteComfirm.close();
  }
  /* istanbul ignore next */
  DeletePHZRecordConfirm() {
    this.PHZRecordDeleteComfirm.close();
    this.deleterecord();
  }

  deleteAttachmentCancel() {
    this.AttachmentDeleteConfirm.close();
  }

  deleteAttachmentConfirm() {
    this.AttachmentDeleteConfirm.close();
    this.deleteAttachmentById(this.attachmentToBeDeleted.attachment, this.attachmentToBeDeleted.rowIndex);
  }

  confirmBeforeDeleteAttachment(attachment, index: number) {

    this.attachmentToBeDeleted = {
      attachment: attachment,
      rowIndex: index
    };

    this.AttachmentDeleteConfirm = this.modalService.open(this.attachmentdelete, {
      disableClose: true,
      hasBackdrop: true,
    });
  }

  /* istanbul ignore next */
  confirmBeforeDeletePHZRecord() {
    this.PHZRecordDeleteComfirm = this.modalService.open(this.phzrecorddelete, {
      disableClose: true,
      hasBackdrop: true,
    });
  }

  // for selecting upload file
  onFileChange(event, i) {
    console.log(event);
    console.log(i);
    const index = i.toString();
    if (event.target.files[0].size >= 104857600) {
      this.toastService.error('Upload File should be smaller than 100MB');
    } else {
      (this.premiseHazardRecordForm.controls.Attachment as FormArray).get(index).patchValue({
        Size: event.target.files[0].size,
        Title: event.target.files[0].name,
      });
    }
  }
  public async CCDriveServer(i) {
    const attachmentFile = (document.getElementById(`ActualAttachment${i.toString()}`) as HTMLInputElement).files[0];
    if (this.premiseHazardRecordForm.controls.Attachment.value[i].Size === 0
      || attachmentFile === null
      || attachmentFile === undefined
    ) {
      this.toastService.error('No file is uploaded!');
      return;
    }
    const confirmSpinner = document.getElementById('Confirmspinner' + i.toString()) as HTMLElement;
    confirmSpinner.hidden = false;
    console.log(this.premiseHazardRecordForm.controls.Attachment.value[i]);

    const attachmentUploadRequest = this.rest.initiateAttachmentUpload(
      this.premiseHazardRecordForm.controls.Attachment.value[i].Title,
      this.premiseHazardRecordForm.controls.Attachment.value[i].Description,
      this.premiseHazardRecordForm.controls.ExternalId.value,
      attachmentFile,
      this.currentCustomer,
      this.currentAgency
    );

    firstValueFrom(attachmentUploadRequest)
      .then((res) => {
        this.toastService.success('Attachment saved successfully!', undefined, { autoDismiss: true && 3000, });
        (document.getElementById('ConfirmCheck' + +i.toString()) as HTMLElement).hidden = false;
        confirmSpinner.hidden = true;
        (document.getElementById('AttachmentConfirmButton' + i.toString()) as HTMLButtonElement).disabled = true;
        (document.getElementById('AttachmentTitle' + i.toString()) as HTMLInputElement).readOnly = true;
        (document.getElementById('AttachmentDescription' + i.toString()) as HTMLInputElement).readOnly = true;
        (document.getElementById('ActualAttachment' + i.toString()) as HTMLInputElement).disabled = true;
        (document.getElementById('addAttachmentButton' + i.toString()) as HTMLButtonElement).disabled = true;
      })
      .catch((err) => {
        confirmSpinner.hidden = true;
        console.log(err);
      });

  }

  /* istanbul ignore next */
  public downloadAttachment(attachment) {
    console.log("downloadAttachment", attachment);
    firstValueFrom(this.http.get(attachment.uri, { responseType: 'blob' }))
      .then((response) => {
        const newBlob = new Blob([response], { type: attachment.fileType })
        const data = window.URL.createObjectURL(newBlob);
        const link = document.createElement("a");
        link.href = data;
        link.download = attachment.fileName;
        link.click();
      })
      .catch((err) => {
        this.toastService.error(`An error occurred while trying to download the "${attachment.fileName}" file.`);
        console.log(err);
      });
  }
  /* istanbul ignore next */
  public viewAttachment(uriInline) {
    window.open(uriInline, '_blank');
  }
  /* istanbul ignore next */
  public deleteAttachment(deletePath, index: number) {
    this.deleteAttachments.push(deletePath);
    this.AttachmentsURI.splice(index, 1);
  }

  public deleteAttachmentById(attachment, index: number) {
    const deleteAttachmentObservable = this.rest.deleteAttachmentById(
      attachment.externalAttachmentId,
      this.premiseHazardRecordForm.value.ExternalId,
      this.currentCustomer,
      this.currentAgency
    );
    firstValueFrom(deleteAttachmentObservable)
      .then(res => {
        this.AttachmentsURI.splice(index, 1);
        this.toastService.success(`The attachment "${attachment.fileName}" has been removed!`, undefined, { autoDismiss: true && 3000 });
      })
      .catch(err => {
        this.toastService.error(`An unexpected error occured while trying to delete an attachment. Please try again or contact the support.`);
        console.error(err);
      });
  }

  public switchAgency(CustomerId, AgencyId) {
    while (this.deleteContacts.length) {
      this.deleteContacts.pop();
    }
    while (this.deletePhones.length) {
      this.deletePhones.pop();
    }
    while (this.AttachmentsURI.length) {
      this.AttachmentsURI.pop();
    }
    while (this.HazardTypes.length) {
      this.HazardTypes.pop();
    }
    while (this.HazardTypesSelect.length) {
      this.HazardTypesSelect.pop();
    }
    this.rowindex = 0;
    (this.Records as FormArray).clear();
    this.premiseHazardRecordForm.reset();
    this.currentNumberOfRecords = 0;
    this.totalNumberOfRecords = 0;
    this.loadRecords(this.currentCustomer, this.currentAgency);
  }

  /* istanbul ignore next */
  // use for checking if needed to load more data
  scrollHandler($event) {
    if (
      $event.srcElement.scrollTop + $event.srcElement.offsetHeight >= $event.srcElement.scrollHeight - 500 &&
      this.continuationToken &&
      this.LoadingLock === false
    ) {
      this.LoadingLock = true;
      this.loadExtraRecords(this.currentCustomer, this.currentAgency);
    }
  }

  public loadExtraRecords(CustomerId, AgencyId) {
    /* istanbul ignore next */
    setTimeout(() => {
      if (this.LoadingLock === true) {
        (document.getElementById('navSideSpinner') as HTMLElement).hidden = false;
      }
    }, 1000);
    this.rest
      .getPremiseHazardRecords(CustomerId, AgencyId, this.continuationToken)
      .toPromise()
      .then(
        (data) => {
          (document.getElementById('navSideSpinner') as HTMLElement).hidden = true;
          this.LoadingLock = false;
          // tslint:disable-next-line:prefer-const
          let RecordsSettings: any;
          let ResponseBody: any;
          ResponseBody = data;
          RecordsSettings = ResponseBody.premiseHazards;
          this.continuationToken = ResponseBody.continuationToken;
          for (const i of RecordsSettings) {
            this.currentNumberOfRecords++;
            const recordOfEachRow = this.buildRecord(i);
            this.Records.push(recordOfEachRow);
          }
        },
        (err) => {
          this.LoadingLock = false;
          (document.getElementById('navSideSpinner') as HTMLElement).hidden = true;
          if (err.status === 400 || err.status === 500) {
            this.toastService.error('Fail to load premise hazard records! - ' + err.error);
          } else {
            /* istanbul ignore else */
            if (err.status !== 404) {
              this.toastService.error('Fail to load premise hazard records! - ' + err.statusText);
            }
            console.log(err);
          }
        }
      );
  }

  public async loadTenantConfigurations(customerId: string) {
    try {
      let tenantId = await firstValueFrom(this.rest.getTenantId(customerId)) as string;
      let tenantInfo = await firstValueFrom(this.rest.getPremiseHazardTenantInfo(tenantId)) as any;
      this.editEnabled = tenantInfo.phzEditEnabled;
      this.recordEditEnabled = tenantInfo.phzEditEnabled;
      this.createEnabled = tenantInfo.phzEditEnabled;
      if (!this.editEnabled) {
        this.toastService.warning("Editing records is disabled for this customer. Contact support to enable this feature.");
      }
    } catch (err) {
      this.editEnabled = true;
      this.recordEditEnabled = true;
      this.createEnabled = true;
      console.log(err);
    }
  }
}
