import { Component, OnInit, TemplateRef, ViewChild, OnDestroy } from '@angular/core';
import { FormArray, FormGroup, FormControl } from '@angular/forms';
import { RestService } from '../services/rest.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { ToastService, MsiModalRef, ModalService } from '@msi/cobalt';
import { Guid } from 'guid-typescript';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-types-proximities-page',
  templateUrl: './types-proximities-page.component.html',
})
export class TypesProximitiesPageComponent implements OnInit, OnDestroy {
  @ViewChild('typedelete', { static: true }) typedelete: TemplateRef<any>;
  private TypeDeleteComfirm: MsiModalRef;
  delettypeindex;
  currentAgency;
  currentCustomer;
  switchAgencyEventListener;
  beSwitched = false;
  editEnabled = false;
  ReadyListener;
  CustomerIDListener;
  typeProximityFormArray = new FormArray([]);
  defaultInnerDistance = 500;
  defaultOuterDistance = 1000;
  defaultUnit = 'Foot';
  defaultSettings = {
    innerProximityDistance: 500,
    outerProximityDistance: 1000,
    unitOfMeasure: 'Foot',
    agencyId: '',
    id: 0,
    tenantKey: 0,
  };
  editMode = false;
  // below is for buffer row (for further input) default settings
  newrowType = new FormControl('');
  newrowDescription = new FormControl('');
  newrowDirectHitVA = new FormControl(false);
  newrowDirectHitAA = new FormControl(false);
  newrowInnerDistance = new FormControl();
  newrowInnerAA = new FormControl(false);
  newrowInnerVA = new FormControl(false);
  newrowOuterDistance = new FormControl();
  newrowOuterAA = new FormControl(false);
  newrowOuterVA = new FormControl(false);
  newrowSequence = new FormControl(999);
  Schema: { premiseHazardTypes: any[], continuationToken: string };
  deleteTypeList = new Array();
  customerSharedArray = new FormArray([]);
  modalRef: MsiModalRef;
  currentSharingDocumentEtag: any;

  constructor(
    public rest: RestService,
    public router: Router,
    public toastService: ToastService,
    private modalService: ModalService,
  ) { }
  ngOnDestroy(): void {
    if (this.switchAgencyEventListener !== undefined) {
      this.switchAgencyEventListener.unsubscribe();
    }
    /* istanbul ignore else */
    if (this.ReadyListener !== undefined) {
      this.ReadyListener.unsubscribe();
    }
    if (this.CustomerIDListener !== undefined) {
      this.CustomerIDListener.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.ReadyListener = this.rest.Ready.subscribe((ready) => {
      if (ready === true) {
        this.CustomerIDListener = this.rest.currCustomer.subscribe((customer) => {
          this.currentCustomer = customer;
        });
        this.switchAgencyEventListener = this.rest.currentAgency.subscribe((agencyName) => {
          this.currentAgency = agencyName;
          if (this.beSwitched) {
            this.switchAgency(this.currentCustomer, this.currentAgency);
          } else {
            this.beSwitched = true;
            this.loadTypesData(this.currentCustomer, this.currentAgency);
          }
        });
        this.loadTenantConfigurations(this.currentCustomer);
      }
    });
  }


  removerow(i) {
    if (this.typeProximityFormArray.value[i].PremiseHazardTypeKey) {
      this.deleteTypeList.push(this.typeProximityFormArray.value[i].ExternalId);
    }
    this.typeProximityFormArray.removeAt(i);
  }

  public addWarningToast() {
    if (this.newrowDescription.value === '' && this.newrowType.value === '') {
      this.toastService.warning('Please add Hazard Type and Hazard Description');
    } else {
      if (this.newrowDescription.value === '') {
        this.toastService.warning('Please add Hazard Description');
      }
      if (this.newrowType.value === '') {
        this.toastService.warning('Please add Hazard Type');
      }
    }
  }

  addAndConfirmRow() {
    if (this.newrowDescription.value === '' || this.newrowType.value === '') {
      this.addWarningToast();
    } else {
      const newrowinputgroup = new FormGroup({
        ExternalId: new FormControl(''),
        Code: new FormControl(''),
        Description: new FormControl(''),
        DirectHitVisual: new FormControl(),
        DirectHitAudio: new FormControl(),
        InnerProximityDistance: new FormControl(),
        InnerProximityAudio: new FormControl(),
        InnerProximityVisual: new FormControl(),
        OuterProximityDistance: new FormControl(),
        OuterProximityAudio: new FormControl(),
        OuterProximityVisual: new FormControl(),
        DisplayOrder: new FormControl(),
        AgencyId: new FormControl(''),
        TenantKey: new FormControl(),
        CustomerId: new FormControl(''),
      });
      if (!this.newrowInnerDistance.value && this.newrowInnerDistance.value !== 0) {
        this.newrowInnerDistance.setValue(this.defaultInnerDistance);
      }
      if (!this.newrowOuterDistance.value && this.newrowOuterDistance.value !== 0) {
        this.newrowOuterDistance.setValue(this.defaultOuterDistance);
      }
      if (this.newrowInnerDistance.value < 0 || this.newrowOuterDistance.value < 0) {
        this.toastService.warning('Distance must be postive');
        this.newrowInnerDistance.reset();
        this.newrowOuterDistance.reset();
      } else {
        if (this.newrowInnerDistance.value > this.newrowOuterDistance.value) {
          this.toastService.warning('Fail to add new record, inner distance cannot be larger than outer distance');
          this.newrowInnerDistance.reset();
          this.newrowOuterDistance.reset();
        } else {
          console.log(this.newrowInnerDistance.value);
          console.log(this.rest.unitConvert.get(this.defaultSettings.unitOfMeasure));
          newrowinputgroup.setValue({
            ExternalId: null,
            Code: this.newrowType.value,
            Description: this.newrowDescription.value,
            DirectHitVisual: this.newrowDirectHitVA.value,
            DirectHitAudio: this.newrowDirectHitAA.value,
            InnerProximityDistance:
              (this.newrowInnerDistance.value * this.rest.unitConvert.get(this.defaultSettings.unitOfMeasure)),
            InnerProximityAudio: this.newrowInnerAA.value,
            InnerProximityVisual: this.newrowInnerVA.value,
            OuterProximityDistance:
              (this.newrowOuterDistance.value * this.rest.unitConvert.get(this.defaultSettings.unitOfMeasure)),
            OuterProximityAudio: this.newrowOuterAA.value,
            OuterProximityVisual: this.newrowOuterVA.value,
            DisplayOrder: this.newrowSequence.value,
            AgencyId: this.currentAgency,
            CustomerId: this.currentCustomer,
            TenantKey: 0,
          });
          console.log(newrowinputgroup);
          this.typeProximityFormArray.push(newrowinputgroup);
          this.clearNewRow();
        }
      }
    }
  }

  clearNewRow() {
    this.newrowType.setValue('');
    this.newrowDescription.setValue('');
    this.newrowDirectHitVA.reset();
    this.newrowDirectHitAA.reset();
    this.newrowInnerDistance.reset();
    this.newrowInnerAA.reset();
    this.newrowInnerVA.reset();
    this.newrowOuterDistance.reset();
    this.newrowOuterAA.reset();
    this.newrowOuterVA.reset();
    this.newrowSequence.reset(999);
  }

  public loadTypesData(CustomerId, AgencyId) {
    this.rest
      .getTypesProximitySchema(CustomerId, AgencyId)
      .toPromise()
      .then(
        (data) => {
          this.Schema = data;
          if (this.Schema != null) {
            this.loadformarray();
          }
        },
        (err) => {
          if (err.status === 400 || err.status === 500) {
            this.toastService.error('Fail to load premise hazard types! - ' + err.error);
          } else {
            if (err.status !== 404) {
              this.toastService.error('Fail to load premise hazard types! - ' + err.statusText);
            }
            console.log(err);
          }
          this.typeProximityFormArray = new FormArray([]);
        }
      );
  }

  public loadformarray() {
    for (const i of this.Schema.premiseHazardTypes) {
      const initialrow = new FormGroup({
        PremiseHazardTypeKey: new FormControl(''),
        ExternalId: new FormControl(''),
        TenantKey: new FormControl(''),
        AgencyId: new FormControl(''),
        Code: new FormControl(''),
        Description: new FormControl(''),
        DirectHitVisual: new FormControl(false),
        DirectHitAudio: new FormControl(false),
        InnerProximityDistance: new FormControl(500),
        InnerProximityAudio: new FormControl(false),
        InnerProximityVisual: new FormControl(false),
        OuterProximityDistance: new FormControl(1000),
        OuterProximityAudio: new FormControl(false),
        OuterProximityVisual: new FormControl(false),
        DisplayOrder: new FormControl(999),
        CcCustomer: new FormControl(''),
        IsSharedData: new FormControl(false),
      });
      initialrow.patchValue({
        PremiseHazardTypeKey: i.id,
        ExternalId: i.externalId,
        AgencyId: i.ccAgency,
        TenantKey: i.externalTenantId,
        Code: i.code,
        Description: i.description,
        DirectHitVisual: i.directHitVisual,
        DirectHitAudio: i.directHitAudio,
        InnerProximityDistance: this.convertFromMeterAndTruncate(i.innerProximityDistance),
        InnerProximityAudio: i.innerProximityAudio,
        InnerProximityVisual: i.innerProximityVisual,
        OuterProximityDistance: this.convertFromMeterAndTruncate(i.outerProximityDistance),
        OuterProximityAudio: i.outerProximityAudio,
        OuterProximityVisual: i.outerProximityVisual,
        DisplayOrder: i.displayOrder,
        CcCustomer: i.ccCustomer,
        IsSharedData: i.ccCustomer !== this.currentCustomer
      });
      if (!i.isDeleted) {
        this.typeProximityFormArray.push(initialrow);
      }
    }
    this.typeProximityFormArray.patchValue(this.sortByShared(this.typeProximityFormArray.value));
    console.log(this.typeProximityFormArray);
    if (this.Schema.continuationToken) {
      this.loadMoreTypesData(this.Schema.continuationToken);
    }
  }

  loadMoreTypesData(continuationToken: string) {
    this.rest
      .getTypesProximitySchema(this.currentCustomer, this.currentAgency, continuationToken)
      .toPromise()
      .then(
        (data) => {
          this.Schema = data;
          if (this.Schema != null) {
            this.loadformarray();
          }
        },
        (err) => {
          this.toastService.error('Failed to load premise hazard types');
          console.log(err);
          this.typeProximityFormArray = new FormArray([]);
        }
      );
  }

  private convertFromMeterAndTruncate(value) {
    return Math.floor((value * this.rest.convertfromMeterTo.get(this.defaultSettings.unitOfMeasure)) * 1000) / 1000;
  }

  private sortByShared(recordsValues) {
    return recordsValues.sort((obj1, obj2) => {
      if (obj1.IsSharedData && !obj2.IsSharedData) {
        return 1;
      }
      if (!obj1.IsSharedData && obj2.IsSharedData) {
        return -1;
      }

      return 0;
    })
  }

  public async updateTypeData() {
    let validData = true;
    const _this = this;
    for (const element of _this.typeProximityFormArray.value) {
      if (element.InnerProximityDistance > element.OuterProximityDistance) {
        this.toastService.warning('Inner distance can not be larger than outer distance');
        return;
      }
    }
    const createSchema = new Array();
    const updateSchema = new Array();
    _this.typeProximityFormArray.controls.filter((e) => !e.value.IsSharedData).forEach((element) => {
      if (!element.value.Code.match(/^[a-zA-Z0-9.,%!@()-\s_]+$/) || !element.value.Description.match(/^[a-zA-Z0-9.,%!@()-\s_]+$/)) {
        validData = false;
      }
      if (!element.value.ExternalId) {
        element.patchValue({ ExternalId: Guid.create().toString() });
        createSchema.push(element.value);
      } else {
        if (element.pristine === false || element.dirty === true) {
          updateSchema.push(element.value);
        }
      }
    });

    if (validData) {
      if (_this.deleteTypeList.length) {
        await this.rest
          .deleteTypes(_this.deleteTypeList, _this.currentCustomer, _this.currentAgency)
          .toPromise()
          .then(
            (res) => {
              this.toastService.success('Deleted Type Successfully');
            },
            (err: HttpErrorResponse) => {
              if (err.status === 500) {
                this.toastService.error('Fail to delete premise hazard type. - ' + err.error);
              } else {
                this.toastService.error('Fail to delete premise hazard type. - ' + err.statusText);
                console.log(err);
              }
            }
          );
      }
      /* istanbul ignore else */
      if (updateSchema.length !== 0) {
        console.log(updateSchema);
        await this.rest
          .upsertTypes(updateSchema, _this.currentCustomer, _this.currentAgency)
          .toPromise()
          .then(
            (res) => {
              this.toastService.success('Updated Types Succesfully');
            },
            (err: HttpErrorResponse) => {
              if (err.status === 500) {
                this.toastService.error('Fail to update premise hazard type. - ' + err.error);
              } else {
                this.toastService.error('Fail to update premise hazard type. - ' + err.statusText);
                console.log(err);
              }
            }
          );
      }
      /* istanbul ignore else */
      if (createSchema.length !== 0) {
        await this.rest
          .upsertTypes(createSchema, _this.currentCustomer, _this.currentAgency)
          .toPromise()
          .then(
            (res) => {
              console.log(res);
              this.toastService.success('Create new type record successfully. ' + res.body);
            },
            (err: HttpErrorResponse) => {
              if (err.status === 400 || err.status === 500) {
                this.toastService.error('Fail to create premise hazard types. - ' + err.error);
              } else {
                this.toastService.error('Fail to create premise hazard types. - ' + err.statusText);
                console.log(err);
              }
            }
          );
      }
    } else {
      this.toastService.error('Type Name and Description cannot contain special characters other than . - and _.');
    }
    this.editModeChange();
  }

  reset_data() {
    while (this.deleteTypeList.length) {
      this.deleteTypeList.pop();
    }
    this.clearNewRow();
    this.typeProximityFormArray.clear();
    this.loadformarray();
    this.defaultOuterDistance = this.defaultSettings.outerProximityDistance;
    this.defaultInnerDistance = this.defaultSettings.innerProximityDistance;
    this.defaultUnit = this.defaultSettings.unitOfMeasure;
  }
  /* istanbul ignore next */
  DeleteTypeCancel() {
    this.TypeDeleteComfirm.close();
  }
  /* istanbul ignore next */
  DeleteTypeConfirm() {
    this.TypeDeleteComfirm.close();
    this.removerow(this.delettypeindex);
  }
  /* istanbul ignore next */
  confirmBeforeDeleteType(i) {
    this.delettypeindex = i;
    this.TypeDeleteComfirm = this.modalService.open(this.typedelete, {
      disableClose: true,
      hasBackdrop: true,
    });
  }

  editModeChange() {
    const editinput = document.getElementsByClassName('disabledinput');
    if (this.editMode === false) {
      console.log(editinput);
      for (const i of editinput) {
        i.removeAttribute('disabled');
      }
    } else {
      for (const j of editinput) {
        j.setAttribute('disabled', 'true');
      }
    }
    this.editMode = !this.editMode;
  }

  public switchAgency(CustomerId, AgencyId) {
    this.currentAgency = AgencyId;
    this.Schema = null;
    this.clearNewRow();
    if (this.deleteTypeList.length) {
      this.deleteTypeList.pop();
    }
    this.typeProximityFormArray.clear();
    this.defaultSettings = {
      unitOfMeasure: 'Foot',
      agencyId: '',
      tenantKey: 0,
      id: 0,
      innerProximityDistance: 500,
      outerProximityDistance: 1000,
    };
    this.defaultInnerDistance = 500;
    this.defaultOuterDistance = 1000;
    this.defaultUnit = 'Foot';
    this.loadTypesData(CustomerId, AgencyId);
  }

  public updateInnerDistance(typeproximity: FormGroup, value) {
    typeproximity.controls.InnerProximityDistance.setValue(
      value * this.rest.unitConvert.get(this.defaultSettings.unitOfMeasure)
    );
    typeproximity.markAsDirty();
  }

  public updateOuterDistance(typeproximity: FormGroup, value) {
    typeproximity.controls.OuterProximityDistance.setValue(
      value * this.rest.unitConvert.get(this.defaultSettings.unitOfMeasure)
    );
    typeproximity.markAsDirty();
  }

  openContentModal(tpl: TemplateRef<any>) {
    this.LoadSharingCustomers(this.currentCustomer, this.currentAgency);
    this.modalRef = this.modalService.open(tpl, {
      disableClose: true,
      hasBackdrop: true,
      width: "450px"
    });
  }

  closeModal() {
    this.modalRef.close();
  }

  RemoveCustomer(i) {
    this.customerSharedArray.removeAt(i);
  }

  AddCustomer() {
    const newrowinputgroup = new FormGroup({ CustomerId: new FormControl(''), NewRecord: new FormControl(false) });
    newrowinputgroup.setValue({
      CustomerId: "",
      NewRecord: true
    });
    this.customerSharedArray.push(newrowinputgroup);
  }

  public async UpdateSharedCustomer() {
    let validData = true;
    const _this = this;
    var dataArray: string[];
    dataArray = [];
    for (const element of _this.customerSharedArray.value) {
      if (!element.CustomerId.match(/^[a-zA-Z0-9.-\s_]+$/)) {
        validData = false;
      }
      else {
        dataArray.push(element.CustomerId);
      }
    }

    if (validData) {
      let etag = this.currentSharingDocumentEtag === undefined ? '' : this.currentSharingDocumentEtag;
      let upsertSharingCustomersCall = this.rest.UpsertSharingCustomers(dataArray, etag, this.currentCustomer, this.currentAgency);
      firstValueFrom(upsertSharingCustomersCall)
        .then(
          (data) => {
            this.currentSharingDocumentEtag = data.headers.get('ETag');
            this.modalRef.close();
            this.toastService.success('Sharing configuration updated successfully!', undefined, {
              autoDismiss: true && 3000,
            });
          },
          (err) => {
            if (err.status === 400 || err.status === 500) {
              this.toastService.error('Failed to update shared customers! - ' + err.error);
            }
            if (err.status === 412) {
              this.toastService.error('Multiple people modifying this configuration. Please refresh the page and try again.');
            }
          }
        );
    }
    else {
      this.toastService.error('Invalid data!');
    }
  }

  public LoadSharingCustomers(CustomerId, AgencyId) {
    this.customerSharedArray = new FormArray([]);
    let GetSharingCustomersCall = this.rest.GetSharingCustomers(CustomerId, AgencyId);
    firstValueFrom(GetSharingCustomersCall)
      .then(
        (data) => {
          this.currentSharingDocumentEtag = data.headers.get('ETag');
          for (var val in data.body) {
            const newrowinputgroup = new FormGroup({ CustomerId: new FormControl(''), NewRecord: new FormControl(false) });
            newrowinputgroup.setValue({
              CustomerId: data.body[val],
              NewRecord: false
            });
            this.customerSharedArray.push(newrowinputgroup);
          }
        },
        (err) => {
          if (err.status === 400 || err.status === 500) {
            this.toastService.error('Fail to load shared customers! - ' + err.error);
          }
          console.log("Bad Empty response");
        }
      );
  }
  public async loadTenantConfigurations(customerId: string) {
    try {
      let tenantId = await firstValueFrom(this.rest.getTenantId(customerId)) as string;
      let tenantInfo = await firstValueFrom(this.rest.getPremiseHazardTenantInfo(tenantId)) as any;
      this.editEnabled = tenantInfo.phzEditEnabled;
      if (!this.editEnabled) {
        this.toastService.warning("Editing types is disabled for this customer. Contact support to enable this feature.");
      }
    } catch (err) {
      this.editEnabled = true;
      console.log(err);
    }
  }
}
