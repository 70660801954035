import {BrowserModule} from '@angular/platform-browser';
import {NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA} from '@angular/core';
import {MsiCommonModule, ALL_MSI_MODULES} from '@msi/cobalt';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppComponent} from './app.component';
import {PremiseHazardRecordComponent} from './premise-hazard-record/premise-hazard-record.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TypesProximitiesPageComponent} from './types-proximities-page/types-proximities-page.component';
import {TokenService} from './services/token.service';
import {AuthGuard} from './services/auth.guard';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {AuthInterceptorService} from './interceptors/auth-interceptor.service';
import {JwtModule} from '@auth0/angular-jwt';
import {RestService} from './services/rest.service';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import {IamAuthGrantConfigToken, UserAuthenticationModule} from '@msi/commandcentral-user-authentication';
import {getAppSpecificIamConfig} from './config/app.config';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { ImportExportModalComponent } from './import-export-modal/import-export-modal.component';
import { DisableControlDirective } from './directives/disable-control';


export function tokenGetter() {
  return localStorage.getItem('access_token');
}
@NgModule({
  declarations: [
    AppComponent,
    TypesProximitiesPageComponent,
    ImportExportModalComponent,
    PremiseHazardRecordComponent,
  ],
  imports: [
    NgxMaskDirective,
    DisableControlDirective,
    NgxMaskPipe,
    CommonModule,
    FormsModule.withConfig({
      callSetDisabledState: 'whenDisabledForLegacyCode',
    }),
    ReactiveFormsModule.withConfig({
      callSetDisabledState: 'whenDisabledForLegacyCode',
    }),
    BrowserModule,
    MsiCommonModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    UserAuthenticationModule,
    JwtModule.forRoot({
      config: {
        tokenGetter,
        allowedDomains: ['example.com'],
        disallowedRoutes: ['http://example.com/examplebadroute/'],
      },
    }),
  ],
  providers: [
    RestService,
    TokenService,
    AuthGuard,
    {
      provide: IamAuthGrantConfigToken,
      useFactory: getAppSpecificIamConfig,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    provideNgxMask()
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule {}
