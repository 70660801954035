import { NgControl } from '@angular/forms';
import { ChangeDetectorRef, Directive, Input, OnChanges, OnInit } from '@angular/core';

@Directive({
  selector: '[disableControl]',
  standalone: true,
})
export class DisableControlDirective implements OnInit, OnChanges {
  constructor(private ngControl: NgControl, private cdr: ChangeDetectorRef) {
  }

  @Input() disableControl?: boolean | null;

  ngOnInit() {
    this.update(!!this.disableControl);
  }

  ngOnChanges() {
    this.update(!!this.disableControl);
  }

  private update(disable: boolean) {
    try {
      const ctrl = this.ngControl.control;
      if (ctrl) {
        if (disable) {
          if (!ctrl.disabled) {
            ctrl.disable({onlySelf: true, emitEvent: false});
            this.cdr.markForCheck();
          }
        } else {
          if (ctrl.disabled) {
            ctrl.enable({onlySelf: true, emitEvent: false});
            this.cdr.markForCheck();
          }
        }
      }
    } catch (e) {
      console?.error(e);
    }
  }
}
