<div id="wrapper">
  <div class="recordList">
    <div id="listHeader">
      <div id="listHeaderItem1">Rec#</div>
      <div id="listHeaderItem2">Prem/Haz Type</div>
      <div id="listHeaderItem3">Record Title</div>
    </div>
    <div *ngIf="Records.value.length == 0" id="listFirstLineWarning">
      <span id="listFirstLineWarningcolor">There are no Premise Hazards</span>
    </div>
    <ng-container *ngIf="Records || Records.length != 0">
      <msi-sidenav role="navigation" class.msi-sidenav-light="light" id="listnav" (scroll)="scrollHandler($event)">
        <msi-item [focus]="rowindex == i"
          [ngClass]="record.get('ExternalId') === null ? 'listnavLineUnsaved' : 'listnavLine'"
          *ngFor="let record of Records.controls; let i = index" (click)="load_record_row(record, i)"
          msiTooltip="This item is read-only, because it is shared by {{record.get('CcCustomer').value}}" 
          [showDelay]="750"
          [tooltipDisabled]="record.get('IsSharedData').value === false">
          <label id="ListItemIndex" *ngIf="record.get('IsSharedData').value === false">{{ i + 1 }}</label>
          <label id="ListItemIndex" *ngIf="record.get('IsSharedData').value === true">
            <msi-icon name="ic_lock_hollow_closed" color="Gray"></msi-icon>
          </label>
          <div *ngIf="record.get('PremiseHazardTypeKey').value != ''" class="listnavLinediv1">
            {{ getPremiseHazardTypeCode(record.get('PremiseHazardTypeKey').value) }}
          </div>
          <div *ngIf="record.get('RecordTitle').value" class="listnavLinediv2">
            {{ record.get('RecordTitle').value }}
          </div>
        </msi-item>
      </msi-sidenav>
      <br />
    </ng-container>
    <div id="searchTitle">
      <label id="searchTitleLabel" class="msi-input-label" for="searchTitleInput">Search Record Title:</label>
      <input [formControl]="searchTitle" id="searchTitleInput" [msiAutocomplete]="auto" class="msi-input"
        autocomplete="off" />
      <msi-autocomplete #auto>
        <div *ngIf="searchTitleIsEmpty | async" class="msi-autocomplete-error-message">No matching entries</div>
        <msi-autocomplete-option (click)="jumpToSearchedRow(title.ExternalId)"
          *ngFor="let title of filteredTitles$ | async" [value]="title['RecordTitle']">{{ title['RecordTitle'] }}
        </msi-autocomplete-option>
      </msi-autocomplete>
    </div>
    <msi-spinner size="medium" [hidden]="true" id="navSideSpinner"></msi-spinner>
    <button class="msi-btn" id="listnavLineButton" *ngIf="createEnabled" (click)="addnewrow()">New</button>
  </div>
  <div class="contentPart" id="content">    
    <div [ngClass]="Records.value.length == 0 ? 'UnableWindow' : 'NormalWindow'" [formGroup]="premiseHazardRecordForm"
      (ngSubmit)="premiseHazardRecordForm.valid && saveUpdate()" class="pt-3">
      <label class="required">Prem/Haz Type:</label>
      <msi-select formControlName="PremiseHazardTypeKey" id="typeCode" placeholder="Select value" *ngIf="recordEditEnabled"
        [disabled]="!recordEditEnabled">
        <msi-select-option *ngFor="let type of HazardTypesSelect" id="typetitle" [value]="type.externalId"
          [disabled]="type.IsSharedData">
          {{ type.code }}
        </msi-select-option>
      </msi-select>
      <msi-select formControlName="PremiseHazardTypeKey" id="typeCode" placeholder="Select value" *ngIf="!recordEditEnabled"
        [disabled]="!recordEditEnabled">
        <msi-select-option *ngFor="let type of HazardTypes" id="typetitle" [value]="type.externalId"
          [disabled]="type.IsSharedData">
          {{ type.code }}
        </msi-select-option>
      </msi-select>

      <br />
      <br />
      <br />
      <label class="required" for="IsTemporary">Perm/Temp:</label>
      <msi-select id="IsTemporary" formControlName="IsTemporary" placeholder="Select value" [disabled]="!recordEditEnabled">
        <msi-select-option value="false">Permanent</msi-select-option>
        <msi-select-option value="true">Temporary</msi-select-option>
      </msi-select>
      <div *ngIf="Records.value.length == 0" id="sideNote2">
        Please click the <strong>New</strong> button before filling in the form!
      </div>
      <br />
      <br />
      <br />
      <msi-checkbox id="IsReviewRequired" formControlName="IsReviewRequired" [disabled]="!recordEditEnabled">Review
        Required</msi-checkbox>
      <br />
      <label for="RecordTitle" class="required">Record Title:</label>
      &nbsp;<input class="msi-input" type="text" formControlName="RecordTitle" required id="RecordTitle"
        [disableControl]="!recordEditEnabled" />
      <br />
      <br />
      <br />
      <label for="PriorityType" class="required">Priority</label>
      <msi-select placeholder="Select Value" id="PriorityType" formControlName="PriorityType" [disabled]="!recordEditEnabled">
        <msi-select-option value="Low">Low</msi-select-option>
        <msi-select-option value="Medium">Medium</msi-select-option>
        <msi-select-option value="High">High</msi-select-option>
        <msi-select-option value="Critical">Critical</msi-select-option>
        <msi-select-option value="Unset">None</msi-select-option>
      </msi-select>
      <div id="sideNote">
        Note: Visual Alerts (VA) for Critical/High Premise Hazard records are only applicable to CAD client
      </div>

      <br />
      <br />
      <msi-radio-group formControlName="AssociateRecordType" value="Location" [disabled]="!recordEditEnabled">
        <msi-radio-button id="associateArea1" value="Area" [disabled]="true">Associate Record with
          Area</msi-radio-button>
        <label id="associateArea2">Prem/Haz Area</label>
        <msi-select [disabled]="true" placeholder="Select value" id="HazardArea">
          <msi-select-option value="Area1">Area1</msi-select-option>
          <msi-select-option value="Area2">Area2</msi-select-option>
          <msi-select-option value="Area3">Area3</msi-select-option>
        </msi-select>
        <msi-radio-button id="associateArea4" [disabled]="true" checked="true" value="Location">Associate Record with
          Location</msi-radio-button>
      </msi-radio-group>

      <label for="location" class="required">Location:</label>
      &nbsp;<input class="msi-input" type="text" pattern="[_~()#!`'a-zA-ZÇ-Ñ-./\s,0-9&*]*" formControlName="Location"
        [disableControl]="!recordEditEnabled" id="location" />
      <br />
      <br />
      <label for="LocName">Loc Name:</label>
      &nbsp;<input class="msi-input" type="text" [disableControl]="!recordEditEnabled"
        pattern="[_~()#!`'a-zA-ZÇ-Ñ-./\s,0-9&*]*" formControlName="LocName" id="LocName" />
      <br />
      <br />
      <div id="Description">Description:</div>
      <br />
      <label for="City">City:</label>
      &nbsp;<input formControlName="City" id="City" [disableControl]="!recordEditEnabled"
        pattern="[_~()#!`'a-zA-ZÇ-Ñ-./\s,0-9&*]*" class="msi-input" type="text" />

      <br />
      <label for="Building">Building:</label>
      &nbsp;<input class="msi-input" type="text" [disableControl]="!recordEditEnabled"
        pattern="[_~()#!`'a-zA-ZÇ-Ñ-./\s,0-9&*]*" formControlName="Building" id="Building" />

      <br />
      <label for="AptUnit">Apt/Unit:</label>
      &nbsp;<input class="msi-input" type="text" [disableControl]="!recordEditEnabled"
        pattern="[_~()#!`'a-zA-ZÇ-Ñ-./\s,0-9&*]*" formControlName="AptUnit" id="AptUnit" />

      <br />
      <label for="Floor">Floor:</label>
      &nbsp;<input class="msi-input" type="text" [disableControl]="!recordEditEnabled"
        pattern="[_~()#!`'a-zA-ZÇ-Ñ-./\s,0-9&*]*" formControlName="Floor" id="Floor" />
      <br />
      <label for="Subdivision">Subdivision:</label>
      &nbsp;<input class="msi-input" type="text" [disableControl]="!recordEditEnabled"
        pattern="[_~()#!`'a-zA-ZÇ-Ñ-./\s,0-9&*]*" formControlName="Subdivision" id="Subdivision" />

      <br />
      <div>
        <label for="ZipCode">ZipCode:</label>
        &nbsp;<input class="msi-input" type="text" formControlName="ZipCode" id="ZipCode"
          [disableControl]="!recordEditEnabled" />
        <button *ngIf="recordEditEnabled" msiTooltip="Check your address validity" class="msi-btn" id="VerifyField"
          (click)="callGeoverificationAPI()">
          Verify
        </button>
        <msi-spinner size="small" id="VerifySpinner" [hidden]="true"></msi-spinner>
      </div>

      <label for="AuthorizingPersonnet">Authorizing Personnel:</label>
      <input class="msi-input" type="text" [disableControl]="!recordEditEnabled" pattern="[_~()#!`'a-zA-ZÇ-Ñ-./\s,0-9&*]*"
        formControlName="AuthorizingPersonnel" id="AuthorizingPersonnel" />
      <br />
      <br />
      <label for="PurgeType">Purge Type:</label>
      <msi-select formControlName="PurgeType" id="PurgeType" [disabled]="true">
        <msi-select-option *ngFor="let type of purgetypes" [value]="type">
          {{ type }}
        </msi-select-option>
      </msi-select>
      <br />
      <br />
      <label for="PurgeDate">Purge Date:</label>
      <msi-icon msiTooltip="You could set specific purge date when purge type is 'Automatically'" [size]="18"
        name="ic_information" id="icInformation1"></msi-icon>
      <input disabled
        [value]="premiseHazardRecordForm.get('PurgeType').value == 'Automatically' ? '0000-00-00T00:00:00' : null"
        [readonly]="premiseHazardRecordForm.get('PurgeType').value == 'Automatically' ? false : true" class="msi-input"
        type="datetime-local" formControlName="PurgeDate" id="PurgeDate" />

      <br />
      <div class="WithDividingLine">
        <span id="OwnerInfo">Owner Information</span>
      </div>

      <label for="OwnerName">Owner Name:</label>
      <input class="msi-input" type="text" pattern="[_~()#!`'a-zA-ZÇ-Ñ-./\s,0-9]*" formControlName="OwnerName"
        [disableControl]="!recordEditEnabled" id="OwnerName" />
      <br />
      <label for="OwnerAddress">Owner Address:</label>
      <input class="msi-input" type="text" pattern="[_~()#!`'a-zA-ZÇ-Ñ-./\s,0-9&*]*" formControlName="OwnerAddress"
        [disableControl]="!recordEditEnabled" id="OwnerAddress" />
      <br />

      <div id="OwnerPhoneNumber_US0">
        <label for="OwnerPhoneNumber_US" id="OwnerPhoneNumber_USLabel">Owner Phone Number:</label>
        <input class="msi-input" type="text" formControlName="OwnerPhone" id="OwnerPhoneNumber_US"
          [disableControl]="!recordEditEnabled" />
      </div>

      <div class="WithDividingLine" id="ContactInfo">
        <span id="ContactInfoColor">Contact Information</span>
      </div>

      <msi-accordion-item [collapsable]="true" formArrayName="Contact">
        <span class="ContactField0"><strong>Contact List ({{ premiseHazardRecordForm.get('Contact').value.length
            }})</strong></span>

        <msi-accordion-item [collapsable]="true"
          *ngFor="let item of premiseHazardRecordForm.get('Contact').value; let i = index">
          <span class="ContactField1"><strong>Contact {{ i + 1 }}</strong></span>

          <msi-accordion-item>
            <div class="ContactField2">
              <label class="ContactField3">Contact Name:</label>
              <input id="ContactName{{ i }}" class="msi-input ContactField4" pattern="[_~()#!`'a-zA-ZÇ-Ñ-./\s,0-9&*]*"
                type="text" [disabled]="!recordEditEnabled" [value]="item['ContactName']['ContactName']" /><button
                *ngIf="recordEditEnabled" (click)="confirmBeforeDeleteContact(i)" class="msi-btn msi-btn-icon ContactField5">
                <msi-icon class="ContactField5Icon" [size]="22" name="ic_menu_unavailable"></msi-icon>
              </button>
              <br />
              <br />
              <div *ngFor="let phone of item['PhoneNumber']; let j = index">
                <label class="ContactField6">Phone Number {{ j + 1 }}:</label>
                <input class="ContactField7 msi-input" id="contact{{ i }}_phone{{ j }}" type="text"
                  [disabled]="!recordEditEnabled" [value]="phone['PhoneNumber']" /><button (click)="deletecontactphone(i, j)"
                  *ngIf="recordEditEnabled" class="msi-btn msi-btn-icon ContactField8">
                  <msi-icon class="ContactField8Icon" [size]="22" name="ic_menu_unavailable"></msi-icon>
                </button>
              </div>
              <br />
              <div class="ContactField9" *ngIf="recordEditEnabled">
                <label class="ContactField12"><i>Add Phone Number:</i></label>
                <input id="phonebuffer{{ i }}" class="ContactField13" /><button (click)="addcontactphone(i)"
                  class="msi-btn msi-btn-icon ContactField11">
                  <msi-icon class="ContactField11Icon" [size]="18" name="ic_add"></msi-icon>
                </button>
                <br />
                <br />
              </div>
            </div>
          </msi-accordion-item>
        </msi-accordion-item>
      </msi-accordion-item>
      <div id="AddNewContact1" *ngIf="recordEditEnabled">
        <div class="AngleLine" id="AddNewContact2">
          <span>Add New Contact:</span>
        </div>
        <button (click)="addNewContact()" class="msi-btn msi-btn-compact msi-btn-small" id="AddNewContact3">
          <msi-icon [size]="16" name="ic_add"></msi-icon>
        </button>
      </div>
      <br />
      <br />
      <div class="WithDividingLine">
        <span id="CommentsLabel">Comments</span>
      </div>
      <textarea pattern="[_~()#!`'a-zA-ZÇ-Ñ-./\s,0-9&*]*" formControlName="Comments" [disableControl]="!recordEditEnabled"
        cols="67" id="Comments"></textarea>

      <div class="WithDividingLine">
        <span id="AttachmentsLabel">Attachments</span>
      </div>
      <br />
      <br />
      <div id="FilesLabel">Files</div>
      <div *ngIf="AttachmentsURI.length == 0" class="attachment-files-empty">
        <span>There are no attachments to this record or you don't have the necessary permissions</span>
      </div>
      <div id="Files1" *ngIf="AttachmentsURI.length > 0">
        <table msi-table id="existing_attachments">
          <thead>
            <tr>
              <th msi-table-header>Title</th>
              <th msi-table-header>Type</th>
              <th msi-table-header>Description</th>
              <th msi-table-header>Download</th>
              <th msi-table-header>View</th>
              <th msi-table-header *ngIf="recordEditEnabled">Delete</th>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let existingAttachment of AttachmentsURI; let j = index">
              <td>{{ existingAttachment.fileName }}</td>
              <td>
                {{ existingAttachment.fileType}}
              </td>
              <td>
                {{existingAttachment.comments}}
              </td>
              <td>
                <button class="msi-btn msi-btn-compact" id="existing_attachments1"
                  (click)="downloadAttachment(existingAttachment)" [disabled]="!existingAttachment.isComplete"
                  [msiTooltip]="!existingAttachment.isComplete ? 'This file is being processed. Try again later.' : 'Download file'"
                  [showDelay]="750">
                  <msi-icon name="ic_download"></msi-icon>
                </button>
              </td>
              <td>
                <button class="msi-btn msi-btn-compact" (click)="viewAttachment(existingAttachment.uri_inline)"
                  [disabled]="!existingAttachment.isComplete"
                  [msiTooltip]="!existingAttachment.isComplete ? 'This file is being processed. Try again later.' : 'View file'"
                  [showDelay]="750">
                  <msi-icon name="ic_visibility_on"></msi-icon>
                </button>
              </td>
              <td *ngIf="recordEditEnabled">
                <button class="msi-btn msi-btn-compact" id="existing_attachments2"
                  (click)="confirmBeforeDeleteAttachment(existingAttachment, j)"
                  [disabled]="!existingAttachment.isComplete"
                  [msiTooltip]="!existingAttachment.isComplete ? 'This file is being processed. Try again later.' : 'Delete file'"
                  [showDelay]="750">
                  <msi-icon name="ic_remove"></msi-icon>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <br />
      <br />
      <br />

      <div *ngIf="recordEditEnabled">
        <label id="UploadAttachmentLabel">Upload Attachment: </label>
        <msi-icon msiTooltip="Maximum upload file size: 100MB" [size]="18" name="ic_information"
          id="UploadAttachmentIcon"></msi-icon>
        <div *ngIf="!premiseHazardRecordForm['controls']['ExternalId'].value" id="UploadAttachmentWarning">
          The upload button will appear once the a PH record is successfully created to upload attachment
        </div>
        <div *ngIf="premiseHazardRecordForm['controls']['ExternalId'].value" formArrayName="Attachment">
          <br />
          <div *ngFor="let attachment of premiseHazardRecordForm['controls']['Attachment']['controls']; let i = index">
            <div [formGroup]="premiseHazardRecordForm['controls']['Attachment']['controls'][i]">
              <div id="UploadAttachmentTitle1">
                <label id="UploadAttachmentTitle2">Title:</label>
                <input class="msi-input UploadAttachmentTitle3" type="text" formControlName="Title"
                  id="AttachmentTitle{{ i }}" pattern="[_~()#!`'a-zA-ZÇ-Ñ-./\s,0-9]*" />
                &nbsp;

                <!-- Using the button to refer the input element and hide input box -->
                <!-- for the onFileChange(), catch the change and update attachment formarray, three attributes: size, title, specific description -->
                <button class="msi-btn-icon msi-btn msi-input UploadAttachmentTitle4" id="addAttachmentButton{{ i }}">
                  <label for="ActualAttachment{{ i }}">
                    <msi-icon [size]="20" name="ic_attachment"></msi-icon>
                  </label>
                </button>
                <input type="file" id="ActualAttachment{{ i }}" class="left1" (change)="onFileChange($event, i)" />

                <!-- addAttachment means that create a space for a new attachment in Attachment FormArray -->

                <button class="msi-btn msi-btn-compact msi-btn-small UploadAttachmentTitle5"
                  id="AttachmentConfirmButton{{ i }}" (click)="CCDriveServer(i)">
                  Upload
                </button>
                <button *ngIf="i == 0" (click)="addAttachment()" class="msi-btn msi-btn-compact msi-btn-small"
                  id="UploadAttachmentTitle6">
                  <msi-icon [size]="16" name="ic_add"></msi-icon>
                </button>
                <br />
                <br />
                <label id="UploadAttachmentTitle7">Description: </label>
                <input class="msi-input UploadAttachmentTitle8" type="text" formControlName="Description"
                  id="AttachmentDescription{{ i }}" />
                <label id="UploadAttachmentTitle9">Size: </label>
                <div *ngIf="
                    premiseHazardRecordForm['controls']['Attachment']['controls'][i]['controls']['Size']['value'] != null
                  " id="UploadAttachmentTitle10">
                  [{{
                  (
                  premiseHazardRecordForm['controls']['Attachment']['controls'][i]['controls']['Size']['value'] /
                  (1024 * 1024)
                  ).toFixed(2)
                  }}
                  MB ]
                </div>
                <msi-spinner size="small" [hidden]="true" id="Confirmspinner{{ i }}"
                  class="UploadAttachmentTitle11"></msi-spinner>
                <div class="UploadAttachmentTitle12">
                  <msi-icon class="UploadAttachmentTitle12Icon" name="ic_check" id="ConfirmCheck{{ i }}"
                    [hidden]="true">
                  </msi-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />

      <div *ngIf="recordEditEnabled">
        <button [disabled]="premiseHazardRecordForm.get('ExternalId').value === null" class="msi-btn msi-btn-action"
          id="deleteButton" (click)="confirmBeforeDeletePHZRecord()">
          Delete
        </button>
        <button class="msi-btn msi-btn-action" (click)="resetrecord()" id="resetButton">Reset</button>
        <button class="msi-btn" id="saveButton" (click)="saveUpdate()" [disabled]="!premiseHazardRecordForm.valid">
          Save
        </button>
      </div>
    </div>
  </div>
</div>
<ng-template #geoverification let-ref="modalRef">
  <msi-content-modal #modal [modalRef]="ref" [items]="matchLocations">
    <msi-content-modal-title>
      <span class="msi-content-modal-title" *ngIf="!matchLocations">No Match Location (0)</span>
      <span class="msi-content-modal-title">Match Locations ({{ matchLocations.length }})</span>
    </msi-content-modal-title>

    <div class="msi-content-modal-item">
      <pre><h4 id="geoverificationResponseNo" ><strong>No. {{modal.index+1}}</strong></h4>
      <button id="geoverificationResponseSelect"  class="msi-btn"
        (click)='confirmlocation(matchLocations[modal.index].matchLocation);ref.close();'>Select</button>
      <div id="geoverificationResponseDistance" >Distance: &nbsp;{{matchLocations[modal.index].distance / this.rest.unitConvert.get(this.defaultDistanceUnit)}} {{this.defaultDistanceUnit}}
      </div>
      <div  id="geoverificationResponseScore" >Score: &nbsp;{{matchLocations[modal.index].score}}
      </div>
      </pre>
      <div id="geoverificationResponseElement">
        <strong>Location Name: {{ matchLocations[modal.index].matchLocation.commonplace }}</strong>
      </div>
      <div>
        <strong>Address: {{ matchLocations[modal.index].matchLocation.houseAddress }}</strong>
      </div>
      <div>
        <strong>Intersection: {{ matchLocations[modal.index].matchLocation.intersection }}</strong>
      </div>
      &nbsp;
      <div id="geoverificationResponseElement">
        <i>State:</i>&nbsp; &nbsp;{{ matchLocations[modal.index].matchLocation.state }}
      </div>
      <div id="geoverificationResponseElement">
        <i>City:</i> &nbsp;&nbsp;{{ matchLocations[modal.index].matchLocation.city }}
      </div>
      <div id="geoverificationResponseElement">
        <i>Subdivision:</i> &nbsp;&nbsp;{{ matchLocations[modal.index].matchLocation.subdivision }}
      </div>
      <div id="geoverificationResponseElement">
        <i>Apartment:</i> &nbsp;&nbsp;{{ matchLocations[modal.index].matchLocation.apartment }}
      </div>
      <div id="geoverificationResponseElement">
        <i>Building:</i> &nbsp;&nbsp;{{ matchLocations[modal.index].matchLocation.building }}
      </div>
      <div id="geoverificationResponseElement">
        <i>Floor:</i> &nbsp;&nbsp;{{ matchLocations[modal.index].matchLocation.floor }}
      </div>
      <div id="geoverificationResponseElement">
        <i>Zip Code:</i> &nbsp;&nbsp;{{ matchLocations[modal.index].matchLocation.zipCode }}
      </div>
      <div id="geoverificationResponseElement">
        <i>Longitude:</i> &nbsp;&nbsp;{{ matchLocations[modal.index].matchLocation.x }}
      </div>
      <div id="geoverificationResponseElement">
        <i>Latitude:</i> &nbsp;&nbsp;{{ matchLocations[modal.index].matchLocation.y }}
      </div>
      <div id="geoverificationResponseElement">
        <i>LocationType:</i> &nbsp;&nbsp;{{ matchLocations[modal.index].matchLocation.locationMark }}
      </div>
      <div *ngIf="matchLocations[modal.index].matchLocation.description !== null">
        Note:&nbsp;&nbsp;
        {{ matchLocations[modal.index].matchLocation.description }}
      </div>
    </div>
  </msi-content-modal>
</ng-template>

<ng-template #contactdelete>
  <msi-modal>
    <msi-modal-header>DELETE CONTACT</msi-modal-header>
    <msi-modal-content>You would delete a contact</msi-modal-content>
    <msi-modal-actions align="end">
      <button class="msi-btn msi-btn-action" (click)="DeleteContactCancel()">Cancel</button>
      <button class="msi-btn spinner-parent" (click)="DeleteContactConfirm()">Confirm</button>
    </msi-modal-actions>
  </msi-modal>
</ng-template>

<ng-template #phzrecorddelete>
  <msi-modal>
    <msi-modal-header>DELETE Premise Hazard RECORD</msi-modal-header>
    <msi-modal-content>You would delete a premise hazard record</msi-modal-content>
    <msi-modal-actions align="end">
      <button class="msi-btn msi-btn-action" (click)="DeletePHZRecordCancel()">Cancel</button>
      <button class="msi-btn spinner-parent" (click)="DeletePHZRecordConfirm()">Confirm</button>
    </msi-modal-actions>
  </msi-modal>
</ng-template>

<ng-template #attachmentdelete>
  <msi-modal>
    <msi-modal-header>DELETE Attachment RECORD</msi-modal-header>
    <msi-modal-content>Do you want to delete "{{this.attachmentToBeDeleted.attachment.fileName}}"
      attachment?</msi-modal-content>
    <msi-modal-actions align="end">
      <button class="msi-btn msi-btn-action" (click)="deleteAttachmentCancel()">Cancel</button>
      <button class="msi-btn spinner-parent" (click)="deleteAttachmentConfirm()">Confirm</button>
    </msi-modal-actions>
  </msi-modal>
</ng-template>