<div class="d-flex">
  <button class="msi-btn msi-btn-action" (click)="onImport()" disabled id="import">Import</button>
  <button class="msi-btn msi-btn-action" (click)="onExport()" disabled id="export">Export</button>
</div>

<ng-template #tpl>
  <msi-modal size="medium" id="modal">
    <msi-modal-content>
      <msi-tab-group>
        <msi-tab label="Import">
          <msi-file-upload maxUploadSize="100MB" #upload>
            <!-- the showAddFolderBtn attribute requires the newest version of Cobalt -->
            <msi-file-upload-footer [showAddFolderBtn]="false"></msi-file-upload-footer>
          </msi-file-upload>
        </msi-tab>
        <msi-tab label="Errors" *ngIf="(importState.errors | keyvalue)?.length">
          <msi-tab-group>
            <msi-tab *ngFor="let error of importState.errors | keyvalue" [label]="error.key">
              <p class="mb-2" *ngFor="let globalError of error.value.globalErrors">Error: {{ globalError }}</p>
              <div *ngIf="(error.value.invalidRows | keyvalue)?.length > 0">
                <div class="mb-3" *ngFor="let invalidRows of error.value.invalidRows | keyvalue">
                  <h5>Sheet "{{ invalidRows.key }}"</h5>
                  <div *ngFor="let invalidRow of invalidRows.value">
                    <h6>Row {{ invalidRow.rowNumber }}:</h6>
                    <p *ngFor="let validationFailure of invalidRow.validationFailures">
                      Column '{{ validationFailure.propertyName }}': {{ validationFailure.errorMessage }}
                    </p>
                  </div>
                </div>
              </div>
            </msi-tab>
          </msi-tab-group>
        </msi-tab>
        <msi-tab label="Ignored Rows" *ngIf="(importState.warnings | keyvalue)?.length">
          <msi-tab-group>
            <msi-tab *ngFor="let warning of importState.warnings | keyvalue" [label]="warning.key">
              <div class="mb-3" *ngFor="let ignoredRows of warning.value.ignoredRows | keyvalue">
                <h5>Sheet "{{ ignoredRows.key }}"</h5>
                <div *ngFor="let ignoredRow of ignoredRows.value">
                  <p>Row {{ ignoredRow.rowNumber }}: {{ ignoredRow.error }}</p>
                </div>
              </div>
            </msi-tab>
          </msi-tab-group>
        </msi-tab>
      </msi-tab-group>
    </msi-modal-content>
    <msi-modal-actions>
      <button class="msi-btn" (click)="dismissImport()" id="close-modal">Dismiss</button>
    </msi-modal-actions>
  </msi-modal>
</ng-template>
