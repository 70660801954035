<div id="TPP1">  
  <div class="border" id="TPP12">
    <div>
      <h5 id="TPP13">Premise/Hazard Types and Proximities</h5>
      <h4 id="TPP14">Note: Visual Alerts (VA) / Audio Alerts (AA)</h4>
      <br />
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;Leave Proximities blank to use the agency default. Enter "0" for inner and outer
        proximity for direct hits.
      </div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;( <strong>Please Note: </strong> Premise hazard records within 5 meters are considered
        direct hits )
      </div>
    </div>
    <br />
    <div id="TPP15">
      <table msi-table id="my-table" class.msi-table-condensed="condensed">
        <thead>
          <tr>            
            <th id="TPP16" msi-table-header>Type <span id="TPP17">&#42;</span></th>
            <th id="TPP18" msi-table-header>Description <span id="TPP19">&#42;</span></th>
            <th id="TPP20" msi-table-header>DirectHitVA</th>
            <th id="TPP21" msi-table-header>DirectHitAA</th>
            <th id="TPP22" msi-table-header>InnerDistance</th>
            <th id="TPP23" msi-table-header>InnerVA</th>
            <th id="TPP24" msi-table-header>InnerAA</th>
            <th id="TPP25" msi-table-header>OuterDistance</th>
            <th id="TPP26" msi-table-header>OuterVA</th>
            <th id="TPP27" msi-table-header>OuterAA</th>
            <th id="TPP28" msi-table-header>Seq #</th>
            <th id="TPP29" msi-table-header *ngIf="editEnabled">Action</th>
            <th id="TPP30" msi-table-header *ngIf="editEnabled"></th>
          </tr>
        </thead>

        <tbody id="TPP31">
          <tr *ngFor="let typeproximity of typeProximityFormArray.controls; let i = index" 
            msiTooltip="This item is read-only, because it is shared by {{typeproximity.value.CcCustomer}}" 
            position="before" 
            [showDelay]="750" 
            [tooltipDisabled]="!typeproximity.value.IsSharedData"
            >
            <td id="TPP32">
              <input
                [ngClass]="typeproximity.value.IsSharedData ? '' : 'disabledinput'"
                class="TPP33"
                pattern="[a-zA-Z0-9.,%!@()-\s_]*"
                [formControl]="typeproximity.get('Code')"
                disabled
              />
            </td>
            <td id="TPP34">
              <textarea
                [ngClass]="typeproximity.value.IsSharedData ? '' : 'disabledinput'"
                class="TPP35"
                pattern="[a-zA-Z0-9.,%!@()-\s_]*"
                [formControl]="typeproximity.get('Description')"
                disabled
              ></textarea>
            </td>
            <td id="TPP36">
              <msi-checkbox [formControl]="typeproximity.get('DirectHitVisual')" [disabled]="!editMode || typeproximity.value.IsSharedData"></msi-checkbox>
            </td>
            <td id="TPP37">
              <msi-checkbox [formControl]="typeproximity.get('DirectHitAudio')" [disabled]="!editMode || typeproximity.value.IsSharedData"></msi-checkbox>
            </td>
            <td id="TPP38">
              <input
                [disabled]="!editMode || typeproximity.value.IsSharedData"
                [ngClass]="typeproximity.value.IsSharedData ? '' : 'disabledinput'"
                class="TPP39"
                (change)="updateInnerDistance(typeproximity, $event.target.value)"
                [value]="
                  typeproximity.get('InnerProximityDistance').value /
                  this.rest.unitConvert.get(defaultSettings.unitOfMeasure)
                "
              />&nbsp;<strong>{{ defaultSettings.unitOfMeasure }}</strong>
            </td>
            <td id="TPP40">
              <msi-checkbox
                [formControl]="typeproximity.get('InnerProximityVisual')"
                [disabled]="!editMode || typeproximity.value.IsSharedData"
              ></msi-checkbox>
            </td>
            <td id="TPP41">
              <msi-checkbox
                [formControl]="typeproximity.get('InnerProximityAudio')"
                [disabled]="!editMode || typeproximity.value.IsSharedData"
              ></msi-checkbox>
            </td>
            <td id="TPP42">
              <input
                disabled
                [ngClass]="typeproximity.value.IsSharedData ? '' : 'disabledinput'"
                class="TPP43"
                (change)="updateOuterDistance(typeproximity, $event.target.value)"
                [value]="
                  typeproximity.get('OuterProximityDistance').value /
                  this.rest.unitConvert.get(defaultSettings.unitOfMeasure)
                "
              />&nbsp;<strong>{{ defaultSettings.unitOfMeasure }}</strong>
            </td>
            <td id="TPP44">
              <msi-checkbox
                [formControl]="typeproximity.get('OuterProximityVisual')"
                [disabled]="!editMode || typeproximity.value.IsSharedData"
              ></msi-checkbox>
            </td>
            <td id="TPP45">
              <msi-checkbox
                [formControl]="typeproximity.get('OuterProximityAudio')"
                [disabled]="!editMode || typeproximity.value.IsSharedData"
              ></msi-checkbox>
            </td>
            <td id="TPP46">
              <input disabled class="TPP47" [formControl]="typeproximity.get('DisplayOrder')" [ngClass]="typeproximity.value.IsSharedData ? '' : 'disabledinput'" />
            </td>
            <td>
              <button class="msi-btn msi-btn-icon" (click)="confirmBeforeDeleteType(i)" *ngIf="editEnabled && !typeproximity.value.IsSharedData">
                <msi-icon name="ic_remove"></msi-icon>
              </button>
              <msi-icon name="ic_lock_hollow_closed" color="Gray" style="padding-left: 4px;" *ngIf="typeproximity.value.IsSharedData"></msi-icon>
            </td>
            <td
              *ngIf="
                editEnabled && 
                typeproximity.get('InnerProximityDistance').value > typeproximity.get('OuterProximityDistance').value
              "
            >
              <msi-icon
                id="TPP48"
                name="ic_emergency"
                msiTooltip="The inner distance cannot be larger than outerdistance"
              ></msi-icon>
            </td>           
          </tr>
          <tr *ngIf="editEnabled">
            <td>
              <input [formControl]="newrowType" pattern="[a-zA-Z0-9.-\s_]*" type="text" id="TPP49" />
            </td>
            <td>
              <input [formControl]="newrowDescription" pattern="[a-zA-Z0-9.-\s_]*" type="text" id="TPP50" />
            </td>
            <td>
              <msi-checkbox [formControl]="newrowDirectHitVA"></msi-checkbox>
            </td>
            <td>
              <msi-checkbox [formControl]="newrowDirectHitAA"></msi-checkbox>
            </td>
            <td>
              <input type="number" min="0" id="TPP51" [formControl]="newrowInnerDistance" />
              <i>&nbsp;{{ defaultSettings.unitOfMeasure }}</i>
            </td>
            <td><msi-checkbox [formControl]="newrowInnerVA"></msi-checkbox></td>
            <td><msi-checkbox [formControl]="newrowInnerAA"></msi-checkbox></td>
            <td>
              <input type="number" min="0" class="distanceinput" id="TPP52" [formControl]="newrowOuterDistance" /><i
                >&nbsp;{{ defaultSettings.unitOfMeasure }}</i
              >
            </td>
            <td><msi-checkbox [formControl]="newrowOuterVA"></msi-checkbox></td>
            <td><msi-checkbox [formControl]="newrowOuterAA"></msi-checkbox></td>
            <td>
              <input id="TPP53" type="number" [formControl]="newrowSequence" />
            </td>
            <td>
              <button class="msi-btn msi-btn-icon" (click)="addAndConfirmRow()">
                <msi-icon name="ic_add"></msi-icon>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div id="TPP54">
      <button class="msi-btn msi-btn-action" (click)="openContentModal(shareData)">Share</button>
      <button class="msi-btn msi-btn-action" (click)="editModeChange()" *ngIf="editEnabled">Edit</button>
      <button class="msi-btn msi-btn-action" (click)="reset_data()" *ngIf="editEnabled">Reset</button>
      <button type="submit" (click)="updateTypeData()" class="msi-btn" *ngIf="editEnabled">Save</button>
    </div>
  </div>
</div>
<ng-template #typedelete>
  <msi-modal>
    <msi-modal-header>DELETE TYPE RECORD</msi-modal-header>
    <msi-modal-content
      >You would delete a type and <strong>all its associated premise hazard records</strong></msi-modal-content
    >
    <msi-modal-actions align="end">
      <button class="msi-btn msi-btn-action" (click)="DeleteTypeCancel()">Cancel</button>
      <button class="msi-btn spinner-parent" (click)="DeleteTypeConfirm()">Confirm</button>
    </msi-modal-actions>
  </msi-modal>
</ng-template>

<ng-template #shareData>
  <msi-modal>
    <msi-modal-header>Share Premise Hazards data</msi-modal-header>
    <msi-modal-content>
      <div>
        <p style="padding-left: 5%; margin-top: 12px;">All premise and hazards data will be shared with following agencies</p>
        <label class="msi-label">Customer/Agency ID</label>
        <div *ngIf="customerSharedArray.controls.length == 0"><button class="linkButton" (click)="AddCustomer()">Add customer</button></div>   
        <table *ngIf="customerSharedArray.controls.length != 0" msi-table id="share-table"
          class.msi-table-condensed="condensed">
          <tbody>
            <tr *ngFor="let customers of customerSharedArray.controls; let i = index" style="border: none;" class="customer-list-row">
              <td class="customer-share-column">
                <input *ngIf="customers.get('NewRecord').value == true" class="SPD5" pattern="[a-zA-Z0-9.-\s_]*"
                  [formControl]="customers.get('CustomerId')" placeholder="Customer/Agency ID" />
                <input *ngIf="customers.get('NewRecord').value == false" class="SPD5" pattern="[a-zA-Z0-9.-\s_]*"
                  [formControl]="customers.get('CustomerId')" disabled />
              </td>
              <td class="remove-customer-column">
                <button class="msi-btn msi-btn-icon" (click)="RemoveCustomer(i)">
                  <msi-icon name="ic_remove"></msi-icon>
                </button>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td style="border: none;">
                <div><button class="linkButton"
                    (click)="AddCustomer()">Add another</button></div>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
      </msi-modal-content
    >
    <msi-modal-actions align="end">
      <button class="msi-btn" style="color: black; background-color: white; border-color: black;" (click)="closeModal()">Discard</button>
      <button class="msi-btn" (click)="UpdateSharedCustomer()">Share</button>
    </msi-modal-actions>
  </msi-modal>
</ng-template>

<style>
  .error {
    border: 1px solid red;
  }
</style>