<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li id="types" class="nav-item active" (click)="markTabAsActive('types')">
        <a class="nav-link" routerLink="/TypesProximitiesPage">Types & Proximities</a>
      </li>
      <li id="records" class="nav-item" (click)="markTabAsActive('records')">
        <a class="nav-link" routerLink="/premisehazardrecord">Records</a>
      </li>
      <li class="nav-link">
        <msi-select [formControl]="currentAgencyRoot" *ngIf="isParentAgencyAdminUser" placeholder="Select Value"
          id="mainpage1">
          <msi-select-option *ngFor="let agency of agencyList" [value]="agency">
            {{ agency }}
          </msi-select-option>
        </msi-select>
      </li>
    </ul>
  </div>
</nav>
<router-outlet></router-outlet>