import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PremiseHazardRecordComponent } from './premise-hazard-record/premise-hazard-record.component';
import { TypesProximitiesPageComponent } from './types-proximities-page/types-proximities-page.component';
import { AuthGuard } from './services/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/TypesProximitiesPage',
    pathMatch: 'full',
  },
  {
    path: 'premisehazardrecord',
    component: PremiseHazardRecordComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'TypesProximitiesPage',
    component: TypesProximitiesPageComponent,
    canActivate: [AuthGuard],
  }
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
