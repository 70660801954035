import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '@msi/commandcentral-user-authentication';
@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {
  constructor(private authenticationService: AuthenticationService) {}

  /* tslint:disable:no-string-literal */

  public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    console.log('Query customer id is ' + route.queryParams.customerId);
    if(route.queryParams.customerId !== undefined)
    {
      sessionStorage.setItem('customer', route.queryParams.customerId);
    }
    return this.isAuthenticated();
  }

  // tslint:disable-next-line:no-identical-functions
  public async canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return this.isAuthenticated();
  }

  public async isAuthenticated(): Promise<boolean> {
    const savedToken = await this.authenticationService.authenticate();
    return !!savedToken;
  }
}
