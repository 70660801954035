import { Injectable, isDevMode } from '@angular/core';

export enum EnvType {
  Local = 'Local',
  Dev = 'Dev',
  QA = 'QA',
  USGovStage = 'usgov-stage',
  Stage = 'Stage',
  Prod = 'usgov',
  CommProd = 'Commerical Production',
  PreProdSec = 'CCCS Preprod',
  ProdSec = 'CCCS Prod',
  CAComm = 'CA Commercial Production',
  AUProd = 'AU Commercial Production',
  FedStage = 'Fed Stage',
  FedProd = 'Fed Prod'
}

export enum AdminVersion {
  Legacy = 'Legacy',
  New = 'New',
}

@Injectable({
  providedIn: 'root',
})
export class EnvService {
  constructor() {}
  private ADMIN_KEY = 'CCADMIN_VERSION';

  get type(): EnvType {
    const origin = window.origin;
    const host = new URL(origin).host;
    let type;
    if (host.includes('localhost')) {
      type = 'local';
    } else {
      type = host.split('.')[1];
    }

    console.log('host:', host);
    console.log('type:', type);

    switch (type) {
      case 'local':
        return EnvType.Local;
      case 'dev':
        return EnvType.Dev;
      case 'qa':
        return EnvType.QA;
      case 'usgov-stage':
        return EnvType.Stage;
      case 'stage':
        return EnvType.Stage;
      case 'commandcentral':
        if(host.includes('.commandcentral.ca'))
        {
          return EnvType.CAComm;
        }
        else if (host.includes('.commandcentral.net.au'))
        {
          return EnvType.AUProd;
        }
        return EnvType.CommProd;
      case 'usgov':
        return EnvType.Prod;
      case 'sec':
        if (host.includes('preprod')) {
          return EnvType.PreProdSec;
        }
        return EnvType.ProdSec;
      case 'fed':
        if (host.includes('stage')) {
          return EnvType.FedStage;
        }
        return EnvType.FedProd;
      default:
        return EnvType.Prod;
    }
  }

  get Admintype(): AdminVersion {
    const parent = document.referrer;
    let Admin;
    const sessionadmin = sessionStorage.getItem('Admin');
    if (sessionadmin !== undefined && sessionadmin !== null) {
      console.log('sessionadmin is ' + sessionadmin);
      Admin = sessionadmin;
    }
    else {
    if (parent) {
      Admin = parent.split('.')[0];
      Admin = Admin.replace('https://', '');
    } else {
      Admin = 'admin';
    }
  }
    console.log('Admintype ' + Admin);
    switch (Admin) {
      case 'admin':
        return AdminVersion.Legacy;
      case 'admin2':
        return AdminVersion.New;
      default:
        return AdminVersion.Legacy;
    }
  }

  //CCAdmin or MSI environment URLs: Ex: https://admin-api.pi.commandcentral.com/api/admin-v2
  envPrefixes = new Map()
    .set(EnvType.Local, '.dev.')
    .set(EnvType.Dev, '.pi.')
    .set(EnvType.QA, '.pi.')
    .set(EnvType.USGovStage, 'usgov-stage.')
    .set(EnvType.Stage, '.pi.')
    .set(EnvType.Prod, '.usgov.')
    .set(EnvType.CAComm, '.')
    .set(EnvType.AUProd, '.')
    .set(EnvType.PreProdSec, '-preprod.sec.')
    .set(EnvType.ProdSec, '.sec.')
    .set(EnvType.FedStage, '.fed-stage.')
    .set(EnvType.FedProd, '.fed.');

  //Our services environment URLs. Ex: https://coregis.stage.commandcentral.com
  envPrefixes1 = new Map()
    .set(EnvType.Local, '.dev.')
    .set(EnvType.Dev, '.dev.')
    .set(EnvType.QA, '.qa.')
    .set(EnvType.USGovStage, '.usgov-stage.')
    .set(EnvType.Stage, '.stage.')
    .set(EnvType.Prod, '.')
    .set(EnvType.CAComm, '.')
    .set(EnvType.AUProd, '.')
    .set(EnvType.PreProdSec, '-preprod.sec.')
    .set(EnvType.ProdSec, '.sec.')
    .set(EnvType.FedStage, '.fed-stage.')
    .set(EnvType.FedProd, '.fed.');

  //UIs URLs. Ex: https://geoverification-admin.stage.commandcentral.ca/location-verify-mapping
  envPrefixes2 = new Map()
    .set(EnvType.Local, 'pi.')
    .set(EnvType.Dev, 'pi.')
    .set(EnvType.QA, 'qa.')
    .set(EnvType.USGovStage, 'pi.')
    .set(EnvType.Stage, 'pi.')
    .set(EnvType.Prod, 'usgov.')
    .set(EnvType.CAComm, '-caprod.')
    .set(EnvType.AUProd, '-auprod.')
    .set(EnvType.PreProdSec, '-preprod.sec.')
    .set(EnvType.ProdSec, '-secprod.sec.')
    .set(EnvType.FedStage, '.fed-stage.')
    .set(EnvType.FedProd, '.fed.');

   //URLs domain extensions i.e. com or ca
   domainPrefix = new Map()
   .set(EnvType.Local, 'com')
   .set(EnvType.Dev, 'com')
   .set(EnvType.USGovStage, 'com')
   .set(EnvType.Stage, 'com')
   .set(EnvType.CommProd, 'com')
   .set(EnvType.CAComm, 'ca')
   .set(EnvType.AUProd, 'net.au')
   .set(EnvType.Prod, 'com')
   .set(EnvType.PreProdSec, 'ca')
   .set(EnvType.ProdSec, 'ca')
   .set(EnvType.FedStage, 'com')
   .set(EnvType.FedProd, 'com');

  AdminPreflexes = new Map().set(AdminVersion.Legacy, 'admin.').set(AdminVersion.New, 'admin2.');

  env = this.envPrefixes.get(this.type);
  env1 = this.envPrefixes1.get(this.type);
  env2 = this.envPrefixes2.get(this.type);
  domainExtension = this.domainPrefix.get(this.type);

  admin = this.AdminPreflexes.get(this.Admintype);

  SetAdmin(admin: string): void {
    if (admin === undefined) {
      admin = 'admin';
    }
    sessionStorage.setItem(this.ADMIN_KEY, admin);
  }
  GetAdmin(): string {
    return sessionStorage.getItem(this.ADMIN_KEY);
  }

  isLocal() {
    return isDevMode();
  }
}
