import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { TokenService } from '../services/token.service';
import { EnvService } from '../services/env.service';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptorService implements HttpInterceptor {
  localToken: string;
  env = this.envService.env;
  env1 = this.envService.env1;
  env2 = this.envService.env2;
  domainExtension = this.envService.domainExtension;
  Jwthelper = new JwtHelperService(); // use for checking the access_token is expired or not
  token: TokenService;
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (
      req.url.match(`https://api.${this.env2}commandcentral.${this.domainExtension}/api/admin`) ||
      req.url.match(`https://admin-api${this.env}commandcentral.${this.domainExtension}/api/admin`) ||
      req.url.match(`https://drive.${this.env}commandcentral.${this.domainExtension}/api/v1/items`) ||
      req.url.match(`https://coregis${this.env1}commandcentral.${this.domainExtension}/admin/v1/`) ||
      req.url.match(`https://coregis${this.env1}commandcentral.${this.domainExtension}/geoverification/v1`) ||
      req.url.match(`https://coregis${this.env1}commandcentral.${this.domainExtension}/premisehazards/v2`) ||
      req.url.match(`https://coregis${this.env1}commandcentral.${this.domainExtension}/sync/premisehazards/v2`) ||
      req.url.match(`https://coregis${this.env1}commandcentral.${this.domainExtension}/admin/premisehazards/v2`) 
      
    ) {
      const JWT = this.tokenService.retrieveToken();
      req = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + JWT),
      });
      return next.handle(req);
    } else {
      return next.handle(req);
    }
  }
  constructor(private tokenService: TokenService, private envService: EnvService) {}
}
