import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root',
})
export class TokenService {
  private TOKEN_KEY = 'JWT_TOKEN';
  retrieveToken(): string {
    return sessionStorage.getItem(this.TOKEN_KEY);
  }
  setToken(data) {
    sessionStorage.setItem(this.TOKEN_KEY, data);
  }
}
